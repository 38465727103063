import { ReactNode } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import { Typography } from '../../../../components/Typography';
import { DropdownIcon } from '../../../../../icons';
import { Badge } from '../../../../components/Badge';

export const EventTypeListItem = ({
  eventResource,
  expanded,
  setExpanded,
  eventTypesMap,
  selectedWebhookEvents,
  children,
}: {
  children: ReactNode;
  eventResource: string;
  eventTypesMap: { [key: string]: any[] };
  expanded: string;
  selectedWebhookEvents: string[];
  setExpanded: any;
}) => {
  const isOpen = eventResource === expanded;
  const selectedEventResourceCount = selectedWebhookEvents.filter((e) =>
    e.includes(eventResource),
  ).length;

  return (
    <AnimatePresence initial={false}>
      <button
        type='button'
        className='flex items-center justify-between w-full py-2 px-4'
        onClick={() => setExpanded(isOpen ? '' : eventResource)}
        data-testid='event-type-list-item-header'
      >
        <div className='flex items-center'>
          <Typography
            variant='paragraph-3'
            weight='medium'
            className='capitalize mr-2'
          >
            {eventResource}
          </Typography>
          {selectedEventResourceCount > 0 && (
            <Badge
              variant='white'
              text={`${selectedEventResourceCount} selected`}
              size='small'
            />
          )}
        </div>
        <div className='flex items-center space-x-3'>
          <Typography>{eventTypesMap[eventResource].length} events</Typography>
          <DropdownIcon
            color='gray-2'
            className={`text-gray-2 ${isOpen ? '!rotate-180' : ''}`}
          />
        </div>
      </button>
      {isOpen && (
        <motion.section
          key='content'
          initial='collapsed'
          animate='open'
          exit='collapsed'
          variants={{
            collapsed: { height: 0, opacity: 0 },
            open: { height: 'auto', opacity: 1 },
          }}
          transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          <div className='px-3'>{children}</div>
        </motion.section>
      )}
    </AnimatePresence>
  );
};
