import { ChangeEventHandler, FC, useCallback, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMutation } from 'react-query';
import { AnimatePresence } from 'framer-motion';
import isEqual from 'react-fast-compare';

import { EnvironmentEnum, ProjectSettings } from '@dynamic-labs/sdk-api';

import { ConfirmToast } from '../../../../components/ConfirmToast';
import { environmentsApi } from '../../../../services/api';
import { useSettingsHasChanged } from '../../../../hooks/useSettingsHasChanged';
import { useEnvironmentId } from '../../Providers/hooks';
import { useSettingsContext } from '../../../../context/SettingsContext';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import Portal from '../../../../components/Portal';
import { SideModalHeader } from '../../../../components/SideModalHeader';
import { EnvironmentTabsLayout } from '../../../../layouts/EnvironmentTabsLayout';
import {
  AuthenticatorIcon,
  PasskeyIcon,
  RecoveryIcon,
} from '../../../../../icons';
import { Typography } from '../../../../components/Typography';
import { Chip } from '../../../../components/Chip';
import { ControlFieldHeader } from '../../../../components/ControlFieldHeader';

import { MFASecuritySettings } from './components';

type MfaModalProps = {
  onClickClose: () => void;
};

const isRequireAtSignUpEnabled = (
  projectEnvironmentSettings: ProjectSettings,
) => projectEnvironmentSettings?.security?.mfa?.required || false;

const isMfaEnabled = (projectEnvironmentSettings: ProjectSettings) =>
  projectEnvironmentSettings?.security?.mfa?.enabled || false;

export const MFAModal: FC<MfaModalProps> = ({ onClickClose }) => {
  const { t } = useTranslation();
  const environmentId = useEnvironmentId();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { enableMfa } = useFlags();
  const { setSettings, settings, initialSettings } = useSettingsContext();

  const {
    cancelChanges: cancelProjectChanges,
    updateInitialSettings: updateProjectInitialSettings,
  } = useSettingsHasChanged('sdk');

  const { mutate: saveProjectSettings, isLoading: isSavingProjectSettings } =
    useMutation(
      async () =>
        environmentId &&
        environmentsApi.updateProjectSettings({
          environmentId,
          projectSettings: settings[activeEnvironmentType],
        }),
      {
        onSuccess: () => {
          updateProjectInitialSettings(
            activeEnvironmentType as EnvironmentEnum,
          );
        },
      },
    );

  const onClickCancelChanges = () => {
    cancelProjectChanges();
  };

  const onClickSave = () => {
    saveProjectSettings();
  };

  const toggleRequireAtSignUp: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        setSettings((previousSettings) => ({
          ...previousSettings,
          [activeEnvironmentType]: {
            ...previousSettings[activeEnvironmentType],
            security: {
              ...previousSettings[activeEnvironmentType].security,
              mfa: {
                ...previousSettings[activeEnvironmentType].security.mfa,
                required: event.target.checked,
              },
            },
          },
        }));
      },
      [activeEnvironmentType, setSettings],
    );

  const toggleMfaEnabled: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setSettings((previousSettings) => ({
        ...previousSettings,
        [activeEnvironmentType]: {
          ...previousSettings[activeEnvironmentType],
          security: {
            ...previousSettings[activeEnvironmentType].security,
            mfa: {
              ...previousSettings[activeEnvironmentType].security.mfa,
              enabled: event.target.checked,
            },
          },
        },
      }));
    },
    [activeEnvironmentType, setSettings],
  );

  const settingsHaveChanged = useMemo(
    () =>
      !isEqual(
        initialSettings[activeEnvironmentType].security.mfa,
        settings[activeEnvironmentType].security.mfa,
      ),
    [activeEnvironmentType, initialSettings, settings],
  );

  /**
   * Reset the state when the environmentId changes
   */
  useEffect(() => {
    onClickCancelChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentId]);

  return (
    <Portal
      className='absolute w-2/3 bg-white h-screen right-0 top-0 px-8 pt-[60px] pb-24'
      handleClose={onClickClose}
    >
      <EnvironmentTabsLayout className='!left-0 !w-full'>
        <SideModalHeader
          Icon={RecoveryIcon}
          title={t('integrations.wallets.providers.mfa.title')}
          content={t('integrations.wallets.providers.mfa.subtitle')}
          showClose
          onClose={onClickClose}
        />

        {enableMfa && (
          <>
            <div className='border border-cloud-2 px-4 rounded-xl divide-y flex-col flex mt-2 w-full'>
              <ControlFieldHeader
                disabled={false}
                handleToggleChange={toggleMfaEnabled}
                toggleId='mfa_enable_authenticator_app'
                toggleValue={isMfaEnabled(settings[activeEnvironmentType])}
                isInitialEnabled={Boolean(
                  initialSettings[activeEnvironmentType].security.mfa?.enabled,
                )}
                className='!gap-2'
                header={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <div className='flex gap-3 items-center'>
                    <div className='flex flex-row items-center w-full space-x-2'>
                      <div className='w-8 h-8 flex items-center'>
                        <AuthenticatorIcon />
                      </div>
                      <Typography variant='paragraph-2' weight='bold'>
                        {t(
                          'integrations.wallets.providers.mfa.toggle.enable_authenticator_app.label',
                        )}
                      </Typography>
                    </div>
                  </div>
                }
              />
              <ControlFieldHeader
                disabled={false}
                handleToggleChange={() => {}}
                toggleId='mfa_enable_passkeys'
                toggleValue={false}
                isInitialEnabled={false}
                className='!gap-2'
                header={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <div className='flex flex-row items-center w-full space-x-2'>
                    <div className='w-8 h-8 flex items-center'>
                      <PasskeyIcon />
                    </div>
                    <Typography variant='paragraph-2' weight='bold'>
                      {t(
                        'integrations.wallets.providers.mfa.toggle.enable_passkeys.label',
                      )}
                    </Typography>
                    <Chip
                      color='grey'
                      label={t('integrations.badge.coming_soon')}
                    />
                  </div>
                }
              />
            </div>
            <div className='flex flex-col gap-6 mt-8'>
              <MFASecuritySettings
                disabled={!isMfaEnabled(settings[activeEnvironmentType])}
                toggleRequireAtSignUp={toggleRequireAtSignUp}
                isRequireAtSignUpEnabled={
                  isMfaEnabled(settings[activeEnvironmentType]) &&
                  isRequireAtSignUpEnabled(settings[activeEnvironmentType])
                }
              />
            </div>
          </>
        )}
        <AnimatePresence>
          {(settingsHaveChanged || isSavingProjectSettings) && (
            <ConfirmToast
              confirmationText={t('design_page.confirmButton')}
              cancelText={t('design_page.cancelButton')}
              message={t('design_page.confirmMessage')}
              onConfirm={onClickSave}
              loading={isSavingProjectSettings}
              onCancel={onClickCancelChanges}
              className='!w-10/12 !max-w-max'
            />
          )}
        </AnimatePresence>
      </EnvironmentTabsLayout>
    </Portal>
  );
};
