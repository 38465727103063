import {
  getWalletBookWallet,
  WalletIcon,
  useWalletBookContext,
} from '@dynamic-labs/wallet-book';

import { logger } from '../../services/logger';
import { normalizeWalletName } from '../../utils/normalizeWalletName';

interface Props {
  name: string;
}

const embeddedWallets = ['turnkey', 'turnkeyhd', 'coinbasempc'];

export const WalletName = ({ name }: Props): JSX.Element => {
  const { walletBook } = useWalletBookContext();

  try {
    const walletBookWallet = getWalletBookWallet(walletBook, name);

    // Display 'Dynamic embedded wallet' for embedded wallets
    const walletDisplayName = embeddedWallets.includes(name)
      ? 'Dynamic embedded wallet'
      : walletBookWallet.name;

    return (
      <div className='flex items-center capitalize'>
        <WalletIcon
          walletKey={normalizeWalletName(name)}
          className='mx-[6px] w-4 h-4'
        />
        {walletDisplayName}
      </div>
    );
  } catch {
    logger.error('unknown wallet name', name);
    return <div className='flex items-center capitalize'> Unknown </div>;
  }
};
