/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AllowlistEntry
 */
export interface AllowlistEntry {
    /**
     * 
     * @type {string}
     * @memberof AllowlistEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AllowlistEntry
     */
    allowListId: string;
    /**
     * 
     * @type {string}
     * @memberof AllowlistEntry
     */
    walletPublicKey?: string;
    /**
     * 
     * @type {string}
     * @memberof AllowlistEntry
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AllowlistEntry
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AllowlistEntry
     */
    discordUsername?: string;
    /**
     * 
     * @type {string}
     * @memberof AllowlistEntry
     */
    twitterUsername?: string;
    /**
     * 
     * @type {number}
     * @memberof AllowlistEntry
     */
    farcasterFid?: number;
    /**
     * 
     * @type {string}
     * @memberof AllowlistEntry
     */
    alias?: string;
}

export function AllowlistEntryFromJSON(json: any): AllowlistEntry {
    return AllowlistEntryFromJSONTyped(json, false);
}

export function AllowlistEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllowlistEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'allowListId': json['allowListId'],
        'walletPublicKey': !exists(json, 'walletPublicKey') ? undefined : json['walletPublicKey'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'discordUsername': !exists(json, 'discordUsername') ? undefined : json['discordUsername'],
        'twitterUsername': !exists(json, 'twitterUsername') ? undefined : json['twitterUsername'],
        'farcasterFid': !exists(json, 'farcasterFid') ? undefined : json['farcasterFid'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
    };
}

export function AllowlistEntryToJSON(value?: AllowlistEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'allowListId': value.allowListId,
        'walletPublicKey': value.walletPublicKey,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'discordUsername': value.discordUsername,
        'twitterUsername': value.twitterUsername,
        'farcasterFid': value.farcasterFid,
        'alias': value.alias,
    };
}

