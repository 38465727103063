/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SocialSignInProviderEnum {
    Apple = 'apple',
    Bitbucket = 'bitbucket',
    Coinbasesocial = 'coinbasesocial',
    Discord = 'discord',
    Facebook = 'facebook',
    Farcaster = 'farcaster',
    Github = 'github',
    Gitlab = 'gitlab',
    Google = 'google',
    Instagram = 'instagram',
    Linkedin = 'linkedin',
    Microsoft = 'microsoft',
    Twitch = 'twitch',
    Twitter = 'twitter'
}

export function SocialSignInProviderEnumFromJSON(json: any): SocialSignInProviderEnum {
    return SocialSignInProviderEnumFromJSONTyped(json, false);
}

export function SocialSignInProviderEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialSignInProviderEnum {
    return json as SocialSignInProviderEnum;
}

export function SocialSignInProviderEnumToJSON(value?: SocialSignInProviderEnum | null): any {
    return value as any;
}

