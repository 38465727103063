/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseUser,
    BaseUserFromJSON,
    BaseUserFromJSONTyped,
    BaseUserToJSON,
} from './BaseUser';
import {
    ChainEnum,
    ChainEnumFromJSON,
    ChainEnumFromJSONTyped,
    ChainEnumToJSON,
} from './ChainEnum';
import {
    ChainalysisCheck,
    ChainalysisCheckFromJSON,
    ChainalysisCheckFromJSONTyped,
    ChainalysisCheckToJSON,
} from './ChainalysisCheck';
import {
    JwtVerifiedCredential,
    JwtVerifiedCredentialFromJSON,
    JwtVerifiedCredentialFromJSONTyped,
    JwtVerifiedCredentialToJSON,
} from './JwtVerifiedCredential';
import {
    MfaBackupCodeAcknowledgement,
    MfaBackupCodeAcknowledgementFromJSON,
    MfaBackupCodeAcknowledgementFromJSONTyped,
    MfaBackupCodeAcknowledgementToJSON,
} from './MfaBackupCodeAcknowledgement';
import {
    OAuthAccount,
    OAuthAccountFromJSON,
    OAuthAccountFromJSONTyped,
    OAuthAccountToJSON,
} from './OAuthAccount';
import {
    ProjectSettingsKyc,
    ProjectSettingsKycFromJSON,
    ProjectSettingsKycFromJSONTyped,
    ProjectSettingsKycToJSON,
} from './ProjectSettingsKyc';
import {
    Session,
    SessionFromJSON,
    SessionFromJSONTyped,
    SessionToJSON,
} from './Session';
import {
    UserAllOf,
    UserAllOfFromJSON,
    UserAllOfFromJSONTyped,
    UserAllOfToJSON,
} from './UserAllOf';
import {
    Wallet,
    WalletFromJSON,
    WalletFromJSONTyped,
    WalletToJSON,
} from './Wallet';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    projectEnvironmentId: string;
    /**
     * 
     * @type {Array<JwtVerifiedCredential>}
     * @memberof User
     */
    verifiedCredentials?: Array<JwtVerifiedCredential>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastVerifiedCredentialId?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    alias?: string | null;
    /**
     * Standard ISO 3166-1 alpha-2 two-letter country code
     * @type {string}
     * @memberof User
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    policiesConsent?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    tShirtSize?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    team?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    firstVisit?: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lastVisit?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    newUser?: boolean;
    /**
     * 
     * @type {object}
     * @memberof User
     */
    metadata?: object;
    /**
     * 
     * @type {MfaBackupCodeAcknowledgement}
     * @memberof User
     */
    mfaBackupCodeAcknowledgement?: MfaBackupCodeAcknowledgement | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    btcWallet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    kdaWallet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    ltcWallet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    ckbWallet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    kasWallet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    dogeWallet?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    emailNotification?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    discordNotification?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    newsletterNotification?: boolean | null;
    /**
     * Access lists evaluated by Dynamic when considering access for the user.
     * @type {Array<string>}
     * @memberof User
     */
    lists?: Array<string>;
    /**
     * A whitespace-separate list of permissions associated with the JWT token issued. This conforms to the JWT standard for scope claims: https://datatracker.ietf.org/doc/html/rfc8693#section-4.2
     * @type {string}
     * @memberof User
     */
    scope?: string;
    /**
     * 
     * @type {Array<ProjectSettingsKyc>}
     * @memberof User
     */
    missingFields?: Array<ProjectSettingsKyc>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    walletPublicKey?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    wallet?: string;
    /**
     * 
     * @type {ChainEnum}
     * @memberof User
     */
    chain?: ChainEnum;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<Session>}
     * @memberof User
     */
    sessions?: Array<Session>;
    /**
     * 
     * @type {Array<Wallet>}
     * @memberof User
     */
    wallets?: Array<Wallet>;
    /**
     * 
     * @type {Array<ChainalysisCheck>}
     * @memberof User
     */
    chainalysisChecks?: Array<ChainalysisCheck>;
    /**
     * 
     * @type {Array<OAuthAccount>}
     * @memberof User
     */
    oauthAccounts?: Array<OAuthAccount>;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'projectEnvironmentId': json['projectEnvironmentId'],
        'verifiedCredentials': !exists(json, 'verifiedCredentials') ? undefined : ((json['verifiedCredentials'] as Array<any>).map(JwtVerifiedCredentialFromJSON)),
        'lastVerifiedCredentialId': !exists(json, 'lastVerifiedCredentialId') ? undefined : json['lastVerifiedCredentialId'],
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'policiesConsent': !exists(json, 'policiesConsent') ? undefined : json['policiesConsent'],
        'tShirtSize': !exists(json, 'tShirtSize') ? undefined : json['tShirtSize'],
        'team': !exists(json, 'team') ? undefined : json['team'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'firstVisit': !exists(json, 'firstVisit') ? undefined : (new Date(json['firstVisit'])),
        'lastVisit': !exists(json, 'lastVisit') ? undefined : (new Date(json['lastVisit'])),
        'newUser': !exists(json, 'newUser') ? undefined : json['newUser'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'mfaBackupCodeAcknowledgement': !exists(json, 'mfaBackupCodeAcknowledgement') ? undefined : MfaBackupCodeAcknowledgementFromJSON(json['mfaBackupCodeAcknowledgement']),
        'btcWallet': !exists(json, 'btcWallet') ? undefined : json['btcWallet'],
        'kdaWallet': !exists(json, 'kdaWallet') ? undefined : json['kdaWallet'],
        'ltcWallet': !exists(json, 'ltcWallet') ? undefined : json['ltcWallet'],
        'ckbWallet': !exists(json, 'ckbWallet') ? undefined : json['ckbWallet'],
        'kasWallet': !exists(json, 'kasWallet') ? undefined : json['kasWallet'],
        'dogeWallet': !exists(json, 'dogeWallet') ? undefined : json['dogeWallet'],
        'emailNotification': !exists(json, 'emailNotification') ? undefined : json['emailNotification'],
        'discordNotification': !exists(json, 'discordNotification') ? undefined : json['discordNotification'],
        'newsletterNotification': !exists(json, 'newsletterNotification') ? undefined : json['newsletterNotification'],
        'lists': !exists(json, 'lists') ? undefined : json['lists'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'missingFields': !exists(json, 'missingFields') ? undefined : ((json['missingFields'] as Array<any>).map(ProjectSettingsKycFromJSON)),
        'walletPublicKey': !exists(json, 'walletPublicKey') ? undefined : json['walletPublicKey'],
        'wallet': !exists(json, 'wallet') ? undefined : json['wallet'],
        'chain': !exists(json, 'chain') ? undefined : ChainEnumFromJSON(json['chain']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'sessions': !exists(json, 'sessions') ? undefined : ((json['sessions'] as Array<any>).map(SessionFromJSON)),
        'wallets': !exists(json, 'wallets') ? undefined : ((json['wallets'] as Array<any>).map(WalletFromJSON)),
        'chainalysisChecks': !exists(json, 'chainalysisChecks') ? undefined : ((json['chainalysisChecks'] as Array<any>).map(ChainalysisCheckFromJSON)),
        'oauthAccounts': !exists(json, 'oauthAccounts') ? undefined : ((json['oauthAccounts'] as Array<any>).map(OAuthAccountFromJSON)),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectEnvironmentId': value.projectEnvironmentId,
        'verifiedCredentials': value.verifiedCredentials === undefined ? undefined : ((value.verifiedCredentials as Array<any>).map(JwtVerifiedCredentialToJSON)),
        'lastVerifiedCredentialId': value.lastVerifiedCredentialId,
        'sessionId': value.sessionId,
        'alias': value.alias,
        'country': value.country,
        'email': value.email,
        'firstName': value.firstName,
        'jobTitle': value.jobTitle,
        'lastName': value.lastName,
        'phoneNumber': value.phoneNumber,
        'policiesConsent': value.policiesConsent,
        'tShirtSize': value.tShirtSize,
        'team': value.team,
        'username': value.username,
        'firstVisit': value.firstVisit === undefined ? undefined : (value.firstVisit.toISOString()),
        'lastVisit': value.lastVisit === undefined ? undefined : (value.lastVisit.toISOString()),
        'newUser': value.newUser,
        'metadata': value.metadata,
        'mfaBackupCodeAcknowledgement': MfaBackupCodeAcknowledgementToJSON(value.mfaBackupCodeAcknowledgement),
        'btcWallet': value.btcWallet,
        'kdaWallet': value.kdaWallet,
        'ltcWallet': value.ltcWallet,
        'ckbWallet': value.ckbWallet,
        'kasWallet': value.kasWallet,
        'dogeWallet': value.dogeWallet,
        'emailNotification': value.emailNotification,
        'discordNotification': value.discordNotification,
        'newsletterNotification': value.newsletterNotification,
        'lists': value.lists,
        'scope': value.scope,
        'missingFields': value.missingFields === undefined ? undefined : ((value.missingFields as Array<any>).map(ProjectSettingsKycToJSON)),
        'walletPublicKey': value.walletPublicKey,
        'wallet': value.wallet,
        'chain': ChainEnumToJSON(value.chain),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'sessions': value.sessions === undefined ? undefined : ((value.sessions as Array<any>).map(SessionToJSON)),
        'wallets': value.wallets === undefined ? undefined : ((value.wallets as Array<any>).map(WalletToJSON)),
        'chainalysisChecks': value.chainalysisChecks === undefined ? undefined : ((value.chainalysisChecks as Array<any>).map(ChainalysisCheckToJSON)),
        'oauthAccounts': value.oauthAccounts === undefined ? undefined : ((value.oauthAccounts as Array<any>).map(OAuthAccountToJSON)),
    };
}

