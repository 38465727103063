import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { UserProfile } from '@dynamic-labs/sdk-react-core';
import { Invite, Organization } from '@dynamic-labs/sdk-api';
import { MfaBackupCodeAcknowledgement } from '@dynamic-labs/sdk-api-core';

import { ROUTES } from '../../components/Navigation/data';

export const useAsyncRedirect = ({
  user,
  userInvites,
  hasUserAcceptedInvitation,
  userOrganizations,
}: {
  hasUserAcceptedInvitation: boolean;
  user: UserProfile | undefined;
  userInvites: Invite[];
  userOrganizations: Organization[];
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const pendingMfaAcknowledgement =
      user?.mfaBackupCodeAcknowledgement ===
      MfaBackupCodeAcknowledgement.Pending;

    const shouldRedirect =
      (!userInvites?.length && hasUserAcceptedInvitation) ||
      (!userInvites?.length && !userOrganizations?.length);

    if (shouldRedirect && !pendingMfaAcknowledgement) {
      navigate(ROUTES.overview);
    }
  }, [
    hasUserAcceptedInvitation,
    navigate,
    user?.mfaBackupCodeAcknowledgement,
    userInvites?.length,
    userOrganizations?.length,
  ]);
};
