import { getChainIcon } from '@dynamic-labs/sdk-react-core';
import { WalletIcon } from '@dynamic-labs/wallet-book';

import { Typography } from '../Typography';
import { normalizeWalletName } from '../../utils/normalizeWalletName';

import styles from './wallet-address.module.css';

type Props = {
  address: string | undefined;
  chain?: string;
  userStyle?: string;
  walletName?: string;
};

export const WalletAddress = ({
  address,
  chain,
  userStyle,
  walletName,
}: Props): JSX.Element => {
  let ChainIcon = null;
  if (typeof chain !== 'undefined') {
    ChainIcon = getChainIcon(chain);
  }

  return (
    <div
      className={`${styles.container} ${walletName ? 'flex-row-reverse' : ''} ${
        userStyle || ''
      }`}
    >
      {ChainIcon && (
        <ChainIcon
          className={`${styles.icon} ${walletName ? 'ml-1 !mr-0' : ''}`}
        />
      )}
      <Typography className={styles.address}>{address}</Typography>
      {walletName && (
        <div className={styles.icon}>
          <WalletIcon walletKey={normalizeWalletName(walletName)} />
        </div>
      )}
    </div>
  );
};
