import { TFunction } from 'react-i18next';

import { Wallet } from '@dynamic-labs/sdk-api';

import { WalletName } from '../../../../../components/WalletName';

export const getAccountTabColumns = (
  t: TFunction<'translation', undefined>,
) => [
  {
    Header: '#',
    accessor: (_row: Wallet, i: number) => i + 1,
    className: 'max-w-8 w-8',
    id: 'index',
  },
  {
    Header: t('users_management_table.wallet'),
    accessor: (wallet: Wallet) => <WalletName name={wallet.name} />,
    id: 'wallet',
  },
  {
    Header: t('users_management_table.address'),
    accessor: (wallet: Wallet) => <span>{wallet.publicKey}</span>,
    className: 'text-black-2 font-medium',
    id: 'address',
  },
];
