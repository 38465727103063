import {
  getChainIcon,
  getChainDisplayName,
} from '@dynamic-labs/sdk-react-core';

import styles from './chain-name.module.css';

interface Props {
  className?: string;
  name: string;
}
export const ChainName = ({ name, className }: Props): JSX.Element => {
  const ChainIcon = getChainIcon(name);

  return (
    <div className={styles.container}>
      <ChainIcon className={`${styles.chain_icon} ${className}`} />
      {getChainDisplayName(name)}
    </div>
  );
};
