/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProviderEnum {
    EmailOnly = 'emailOnly',
    MagicLink = 'magicLink',
    Apple = 'apple',
    Bitbucket = 'bitbucket',
    Coinbasesocial = 'coinbasesocial',
    Discord = 'discord',
    Facebook = 'facebook',
    Farcaster = 'farcaster',
    Github = 'github',
    Gitlab = 'gitlab',
    Google = 'google',
    Instagram = 'instagram',
    Linkedin = 'linkedin',
    Microsoft = 'microsoft',
    Twitch = 'twitch',
    Twitter = 'twitter',
    Blocto = 'blocto',
    Banxa = 'banxa',
    Dynamic = 'dynamic',
    Alchemy = 'alchemy',
    Zerodev = 'zerodev',
    Turnkey = 'turnkey',
    CoinbaseWaas = 'coinbaseWaas',
    Sms = 'sms'
}

export function ProviderEnumFromJSON(json: any): ProviderEnum {
    return ProviderEnumFromJSONTyped(json, false);
}

export function ProviderEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderEnum {
    return json as ProviderEnum;
}

export function ProviderEnumToJSON(value?: ProviderEnum | null): any {
    return value as any;
}

