import Moment from 'react-moment';
import { TFunction } from 'react-i18next';
import { getName } from 'country-list';

import { OAuthAccount, User } from '@dynamic-labs/sdk-api';
import { SocialIcon } from '@dynamic-labs/iconic';

import { WalletName } from '../../../../components/WalletName';
import { WalletAddress } from '../../../../components/WalletAddress';
import { ChainName } from '../../../../components/ChainName';
import { TableEmptyData } from '../../../../components/TableEmptyData';
import { TableSkeleton } from '../../../../components/TableSkeleton';

export const usersColumns = (t: TFunction<'translation', undefined>) => [
  {
    Header: '#',
    accessor: (_row: User, i: number) => i + 1,
    className: 'text-gray-2 min-w-[28px]',
    id: 'index',
    sortable: false,
  },
  {
    Header: t('users_management_table.user'),
    accessor: (row: User) => row.id,
    className: 'min-w-[270px]',
    id: 'id',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_management_table.wallet'),
    accessor: ({ wallet }: User) =>
      wallet ? <WalletName name={wallet} /> : <TableEmptyData />,
    className: 'min-w-[116px]',
    id: 'wallet',
    skeleton: <TableSkeleton />,
    // TODO: https://linear.app/dynamic-labs/issue/DYN-876
    // not sortable to accomodate multi-wallet changes
    sortable: false,
  },
  {
    Header: t('users_management_table.address'),
    accessor: ({ walletPublicKey, chain }: User) =>
      walletPublicKey ? (
        <WalletAddress address={walletPublicKey} chain={chain} />
      ) : (
        <TableEmptyData />
      ),
    className: 'min-w-[116px]',
    id: 'walletPublicKey',
    skeleton: <TableSkeleton />,
    // TODO: https://linear.app/dynamic-labs/issue/DYN-876
    // not sortable to accomodate multi-wallet changes
    sortable: false,
  },
  {
    Header: t('users_management_table.oAuthAccounts'),
    accessor: ({ oauthAccounts }: User) =>
      oauthAccounts && oauthAccounts?.length > 0 ? (
        getSocialIcons(oauthAccounts)
      ) : (
        <TableEmptyData />
      ),
    className: 'min-w-[116px] flex justify-center items-center gap-1',
    id: 'oauthAccount',
    skeleton: <TableSkeleton />,
    sortable: false,
  },
  {
    Header: t('users_management_table.chain'),
    accessor: ({ chain }: User) =>
      chain ? <ChainName name={chain} /> : <TableEmptyData />,
    className: 'min-w-[116px]',
    id: 'chain',
    skeleton: <TableSkeleton />,
    // TODO: https://linear.app/dynamic-labs/issue/DYN-876
    // not sortable to accomodate multi-wallet changes
    sortable: false,
  },
  {
    Header: t('users_management_table.first_connect'),
    accessor: ({ firstVisit }: User) =>
      firstVisit ? <Moment fromNow>{firstVisit}</Moment> : <TableEmptyData />,
    className: 'text-gray-2 min-w-[182px]',
    id: 'firstVisit',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_management_table.last_connect'),
    accessor: ({ lastVisit }: User) =>
      lastVisit ? <Moment fromNow>{lastVisit}</Moment> : <TableEmptyData />,
    className: 'text-gray-2 min-w-[182px]',
    id: 'lastVisit',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_management_table.email'),
    accessor: (row: User) => row.email ?? <TableEmptyData />,
    canToggleHidden: true,
    className: 'text-gray-2 min-w-[233px]',
    id: 'email',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_management_table.alias'),
    accessor: (row: User) => row.alias ?? <TableEmptyData />,
    canToggleHidden: true,
    className: 'text-gray-2 min-w-[182px]',
    id: 'alias',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_management_table.first_name'),
    accessor: (row: User) => row.firstName ?? <TableEmptyData />,
    canToggleHidden: true,
    className: 'text-gray-2 min-w-[182px]',
    id: 'firstName',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_management_table.last_name'),
    accessor: (row: User) => row.lastName ?? <TableEmptyData />,
    canToggleHidden: true,
    className: 'text-gray-2 min-w-[182px]',
    id: 'lastName',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_management_table.phone_number'),
    accessor: (row: User) => row.phoneNumber ?? <TableEmptyData />,
    canToggleHidden: true,
    className: 'text-gray-2 min-w-[182px]',
    id: 'phoneNumber',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_management_table.job_title'),
    accessor: (row: User) => row.jobTitle ?? <TableEmptyData />,
    canToggleHidden: true,
    className: 'text-gray-2 min-w-[233px]',
    id: 'jobTitle',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_management_table.t_shirt_size'),
    accessor: (row: User) => row.tShirtSize ?? <TableEmptyData />,
    canToggleHidden: true,
    className: 'text-gray-2 min-w-[233px]',
    id: 'tShirtSize',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_management_table.username'),
    accessor: (row: User) => row.username ?? <TableEmptyData />,
    canToggleHidden: true,
    className: 'text-gray-2 min-w-[182px]',
    id: 'username',
    skeleton: <TableSkeleton />,
  },
];

export const getCountryName = (country?: string | null) =>
  country ? getName(country) : undefined;

export const getSocialIcons = (oauthAccounts: OAuthAccount[]) =>
  oauthAccounts.map((account, index) => {
    if (oauthAccounts.length > 3) {
      if (index < 3) {
        return <SocialIcon className='w-4 h-4' name={account.provider || ''} />;
      }

      if (index === 3) {
        return (
          <div className='w-4 h-4 rounded-full flex items-center justify-center bg-primary-1 text-white text-[10px]'>
            {oauthAccounts.length - 3}
          </div>
        );
      }

      return;
    }
    return <SocialIcon className='w-4 h-4' name={account.provider || ''} />;
  });
