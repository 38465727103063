import { t } from 'i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';

import { KycSettingType } from '../../../hooks/useSetKycFieldProp';

export type ValueType = {
  label: string;
  value: KycSettingType;
};

export type CheckBoxDataType = {
  required: ValueType;
  unique: ValueType;
  verify: ValueType;
};

export const checkboxValue: CheckBoxDataType = {
  required: {
    label: t('integrations.onboarding_and_kyc.kyc-types.required'),
    value: 'required',
  },
  unique: {
    label: t('integrations.onboarding_and_kyc.kyc-types.unique'),
    value: 'unique',
  },
  verify: {
    label: t('integrations.onboarding_and_kyc.kyc-types.verify'),
    value: 'verify',
  },
};

export const titlesData = {
  alias: t('integrations.onboarding_and_kyc.settings.alias'),
  btcWallet: t('integrations.onboarding_and_kyc.settings.btcWallet'),
  ckbWallet: t('integrations.onboarding_and_kyc.settings.ckbWallet'),
  country: t('integrations.onboarding_and_kyc.settings.country'),
  discordNotification: t(
    'integrations.onboarding_and_kyc.settings.discordNotification',
  ),
  email: t('integrations.onboarding_and_kyc.settings.email'),
  emailNotification: t(
    'integrations.onboarding_and_kyc.settings.emailNotification',
  ),
  firstName: t('integrations.onboarding_and_kyc.settings.firstName'),
  jobTitle: t('integrations.onboarding_and_kyc.settings.jobTitle'),
  kasWallet: t('integrations.onboarding_and_kyc.settings.kasWallet'),
  kdaWallet: t('integrations.onboarding_and_kyc.settings.kdaWallet'),
  lastName: t('integrations.onboarding_and_kyc.settings.lastName'),
  ltcWallet: t('integrations.onboarding_and_kyc.settings.ltcWallet'),

  newsletterNotification: t(
    'integrations.onboarding_and_kyc.settings.newsletterNotification',
  ),
  phoneNumber: t('integrations.onboarding_and_kyc.settings.phoneNumber'),
  policiesConsent: t(
    'integrations.onboarding_and_kyc.settings.policiesConsent',
  ),
  social: t('integrations.onboarding_and_kyc.settings.social'),

  tShirtSize: t('integrations.onboarding_and_kyc.settings.tShirtSize'),
  team: t('integrations.onboarding_and_kyc.settings.team'),
  username: t('integrations.onboarding_and_kyc.settings.username'),
};

export const DYNAMIC_SOCIAL_CREDENTIAL_PROVIDERS = [
  ProviderEnum.Discord,
  ProviderEnum.Github,
  ProviderEnum.Google,
  ProviderEnum.Twitch,
];
