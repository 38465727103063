import React, { ReactNode, useRef, useState } from 'react';

import { AnimatePresence, m } from 'framer-motion';

import { Popper } from '../Popper';

import styles from './Tooltip.module.css';

type Props = {
  children: ReactNode;
  className?: string;
  disableHover?: boolean;
  placement?: 'top' | 'bottom';
  tooltipText: string[] | ReactNode;
};

export const Tooltip = ({
  className,
  children,
  tooltipText,
  disableHover = false,
  placement = 'top',
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={styles.wrapper}
      onFocus={() => setIsHovered(true)}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!disableHover && (
        <AnimatePresence>
          {isHovered && (
            <Popper
              anchorRef={ref}
              anchorOrigin={
                placement === 'top' ? 'top-center' : 'bottom-center'
              }
            >
              <m.div
                className={`${styles.tooltip} ${
                  placement === 'top' ? styles.tooltipTop : styles.tooltipBottom
                } ${className}`}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.3 },
                }}
                exit={{ opacity: 0 }}
              >
                {Array.isArray(tooltipText)
                  ? tooltipText.map((line) => <div key={line}>{line}</div>)
                  : tooltipText}
              </m.div>
            </Popper>
          )}
        </AnimatePresence>
      )}

      <div data-testid='tooltip-text' ref={ref}>
        {children}
      </div>
    </div>
  );
};
