import { useEffect, useMemo, useState } from 'react';

import {
  ChainEnum,
  UserFilterableFieldsEnum,
  UsersResponse,
} from '@dynamic-labs/sdk-api';

import { logger } from '../../../../services/logger';
import { usersApi } from '../../../../services/api';
import { SortBy } from '../../../../types';

export type UseFetchUserArgs = {
  activeProjectId: string | undefined;
  selectedEnvironmentId: string | undefined;
  usersChainFilter: ChainEnum | undefined;
  usersSearchFilter: UserFilterableFieldsEnum;
};

export const useFetchUsers = ({
  activeProjectId,
  selectedEnvironmentId,
  usersSearchFilter,
  usersChainFilter,
}: UseFetchUserArgs) => {
  const limit = 20;
  const [userSearchQuery, setUserSearchQuery] = useState<string>();
  const [sortBy, setSortBy] = useState<SortBy>();
  const [offset, setOffset] = useState<number>(0);
  const [users, setUsers] = useState<UsersResponse | undefined>();
  const [page, setPage] = useState<number>(0);
  const [reload, setReload] = useState<boolean>(false);

  // Changing the search filter always triggers an update but
  // we do not want to make an API request when the query is blank since nothing is being filtered
  const memoizedSearchFilter = useMemo(
    () =>
      userSearchQuery
        ? usersSearchFilter
        : UserFilterableFieldsEnum.WalletPublicKey,
    [userSearchQuery, usersSearchFilter],
  );

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (selectedEnvironmentId) {
          const response = await usersApi.getEnvironmentUsers({
            environmentId: selectedEnvironmentId,
            filter: {
              ...(userSearchQuery && {
                filterValue: userSearchQuery,
              }),
              ...(memoizedSearchFilter && {
                filterColumn: memoizedSearchFilter,
              }),
              ...(usersChainFilter && { chain: usersChainFilter }),
            },
            limit,
            offset,
            orderBy: sortBy && `${sortBy.id}-${sortBy.desc ? 'desc' : 'asc'}`,
          });
          setUsers(response);
        }
      } catch (e) {
        logger.error(e);
      } finally {
        setReload(false);
      }
    };

    fetchUsers();
  }, [
    reload,
    selectedEnvironmentId,
    activeProjectId,
    sortBy,
    userSearchQuery,
    memoizedSearchFilter,
    usersChainFilter,
    offset,
  ]);

  useEffect(() => {
    setOffset(0);
    setPage(0);
  }, [
    userSearchQuery,
    usersSearchFilter,
    usersChainFilter,
    sortBy,
    selectedEnvironmentId,
  ]);

  const usersData = useMemo(() => users?.users, [users]);

  return {
    limit,
    offset,
    page,
    setOffset,
    setPage,
    setReload,
    setSortBy,
    setUserSearchQuery,
    total: users?.count ?? 0,
    usersData,
  };
};
