import classNames from 'classnames';

import { Typography } from '../Typography/Typography';

import styles from './Badge.module.css';

type Props = {
  className?: string;
  size: 'small' | 'big';
  text: string;
  variant: 'green' | 'yellow' | 'red' | 'primary' | 'secondary' | 'white';
};

export const Badge = ({ variant, text, size, className }: Props) => {
  const containerSizeClassName = styles[`container--${size}`];
  const colorClassName = styles[`color--${variant}`];

  return (
    <div
      className={classNames(
        styles.container,
        containerSizeClassName,
        colorClassName,
        className,
      )}
    >
      <Typography
        variant={`paragraph-${size === 'small' ? 0 : 2}`}
        weight='medium'
        className={colorClassName}
      >
        {text}
      </Typography>
    </div>
  );
};
