/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JwtBlockchainAccount,
    JwtBlockchainAccountFromJSON,
    JwtBlockchainAccountFromJSONTyped,
    JwtBlockchainAccountToJSON,
} from './JwtBlockchainAccount';
import {
    JwtPayloadDeprecatedInfo,
    JwtPayloadDeprecatedInfoFromJSON,
    JwtPayloadDeprecatedInfoFromJSONTyped,
    JwtPayloadDeprecatedInfoToJSON,
} from './JwtPayloadDeprecatedInfo';
import {
    JwtVerifiedCredential,
    JwtVerifiedCredentialFromJSON,
    JwtVerifiedCredentialFromJSONTyped,
    JwtVerifiedCredentialToJSON,
} from './JwtVerifiedCredential';
import {
    ProjectSettingsKyc,
    ProjectSettingsKycFromJSON,
    ProjectSettingsKycFromJSONTyped,
    ProjectSettingsKycToJSON,
} from './ProjectSettingsKyc';

/**
 * 
 * @export
 * @interface DynamicJwt
 */
export interface DynamicJwt {
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    kid: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    aud: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    iss: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    sub: string;
    /**
     * 
     * @type {number}
     * @memberof DynamicJwt
     */
    exp?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicJwt
     */
    iat?: number;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    sid: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    alias?: string;
    /**
     * 
     * @type {Array<JwtBlockchainAccount>}
     * @memberof DynamicJwt
     */
    blockchainAccounts?: Array<JwtBlockchainAccount>;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    environmentId: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    familyName?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    givenName?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    lastAuthenticatedAccountId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DynamicJwt
     */
    lists?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    phoneNumber?: string;
    /**
     * 
     * @type {Array<ProjectSettingsKyc>}
     * @memberof DynamicJwt
     */
    missingFields: Array<ProjectSettingsKyc>;
    /**
     * A whitespace-separate list of permissions associated with the JWT token issued. This conforms to the JWT standard for scope claims: https://datatracker.ietf.org/doc/html/rfc8693#section-4.2
     * @type {string}
     * @memberof DynamicJwt
     */
    scope?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    tShirtSize?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    team?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    username?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DynamicJwt
     */
    policiesConsent?: boolean;
    /**
     * 
     * @type {JwtBlockchainAccount}
     * @memberof DynamicJwt
     */
    verifiedAccount?: JwtBlockchainAccount;
    /**
     * 
     * @type {Array<JwtVerifiedCredential>}
     * @memberof DynamicJwt
     */
    verifiedCredentials: Array<JwtVerifiedCredential>;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    lastVerifiedCredentialId: string;
    /**
     * 
     * @type {Date}
     * @memberof DynamicJwt
     */
    firstVisit?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DynamicJwt
     */
    lastVisit?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DynamicJwt
     */
    newUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    btcWallet?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    kdaWallet?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    ltcWallet?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    ckbWallet?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    kasWallet?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicJwt
     */
    dogeWallet?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DynamicJwt
     */
    emailNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DynamicJwt
     */
    discordNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DynamicJwt
     */
    newsletterNotification?: boolean;
    /**
     * 
     * @type {object}
     * @memberof DynamicJwt
     */
    metadata?: object;
    /**
     * 
     * @type {JwtPayloadDeprecatedInfo}
     * @memberof DynamicJwt
     */
    info?: JwtPayloadDeprecatedInfo;
}

export function DynamicJwtFromJSON(json: any): DynamicJwt {
    return DynamicJwtFromJSONTyped(json, false);
}

export function DynamicJwtFromJSONTyped(json: any, ignoreDiscriminator: boolean): DynamicJwt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kid': json['kid'],
        'aud': json['aud'],
        'iss': json['iss'],
        'sub': json['sub'],
        'exp': !exists(json, 'exp') ? undefined : json['exp'],
        'iat': !exists(json, 'iat') ? undefined : json['iat'],
        'sid': json['sid'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'blockchainAccounts': !exists(json, 'blockchain_accounts') ? undefined : ((json['blockchain_accounts'] as Array<any>).map(JwtBlockchainAccountFromJSON)),
        'country': !exists(json, 'country') ? undefined : json['country'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'environmentId': json['environment_id'],
        'familyName': !exists(json, 'family_name') ? undefined : json['family_name'],
        'givenName': !exists(json, 'given_name') ? undefined : json['given_name'],
        'jobTitle': !exists(json, 'job_title') ? undefined : json['job_title'],
        'lastAuthenticatedAccountId': !exists(json, 'last_authenticated_account_id') ? undefined : json['last_authenticated_account_id'],
        'lists': !exists(json, 'lists') ? undefined : json['lists'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'missingFields': ((json['missing_fields'] as Array<any>).map(ProjectSettingsKycFromJSON)),
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'tShirtSize': !exists(json, 't_shirt_size') ? undefined : json['t_shirt_size'],
        'team': !exists(json, 'team') ? undefined : json['team'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'policiesConsent': !exists(json, 'policies_consent') ? undefined : json['policies_consent'],
        'verifiedAccount': !exists(json, 'verified_account') ? undefined : JwtBlockchainAccountFromJSON(json['verified_account']),
        'verifiedCredentials': ((json['verified_credentials'] as Array<any>).map(JwtVerifiedCredentialFromJSON)),
        'lastVerifiedCredentialId': json['last_verified_credential_id'],
        'firstVisit': !exists(json, 'first_visit') ? undefined : (new Date(json['first_visit'])),
        'lastVisit': !exists(json, 'last_visit') ? undefined : (new Date(json['last_visit'])),
        'newUser': !exists(json, 'new_user') ? undefined : json['new_user'],
        'btcWallet': !exists(json, 'btc_wallet') ? undefined : json['btc_wallet'],
        'kdaWallet': !exists(json, 'kda_wallet') ? undefined : json['kda_wallet'],
        'ltcWallet': !exists(json, 'ltc_wallet') ? undefined : json['ltc_wallet'],
        'ckbWallet': !exists(json, 'ckb_wallet') ? undefined : json['ckb_wallet'],
        'kasWallet': !exists(json, 'kas_wallet') ? undefined : json['kas_wallet'],
        'dogeWallet': !exists(json, 'doge_wallet') ? undefined : json['doge_wallet'],
        'emailNotification': !exists(json, 'email_notification') ? undefined : json['email_notification'],
        'discordNotification': !exists(json, 'discord_notification') ? undefined : json['discord_notification'],
        'newsletterNotification': !exists(json, 'newsletter_notification') ? undefined : json['newsletter_notification'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'info': !exists(json, 'info') ? undefined : JwtPayloadDeprecatedInfoFromJSON(json['info']),
    };
}

export function DynamicJwtToJSON(value?: DynamicJwt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kid': value.kid,
        'aud': value.aud,
        'iss': value.iss,
        'sub': value.sub,
        'exp': value.exp,
        'iat': value.iat,
        'sid': value.sid,
        'alias': value.alias,
        'blockchain_accounts': value.blockchainAccounts === undefined ? undefined : ((value.blockchainAccounts as Array<any>).map(JwtBlockchainAccountToJSON)),
        'country': value.country,
        'email': value.email,
        'environment_id': value.environmentId,
        'family_name': value.familyName,
        'given_name': value.givenName,
        'job_title': value.jobTitle,
        'last_authenticated_account_id': value.lastAuthenticatedAccountId,
        'lists': value.lists,
        'phone_number': value.phoneNumber,
        'missing_fields': ((value.missingFields as Array<any>).map(ProjectSettingsKycToJSON)),
        'scope': value.scope,
        't_shirt_size': value.tShirtSize,
        'team': value.team,
        'username': value.username,
        'policies_consent': value.policiesConsent,
        'verified_account': JwtBlockchainAccountToJSON(value.verifiedAccount),
        'verified_credentials': ((value.verifiedCredentials as Array<any>).map(JwtVerifiedCredentialToJSON)),
        'last_verified_credential_id': value.lastVerifiedCredentialId,
        'first_visit': value.firstVisit === undefined ? undefined : (value.firstVisit.toISOString()),
        'last_visit': value.lastVisit === undefined ? undefined : (value.lastVisit.toISOString()),
        'new_user': value.newUser,
        'btc_wallet': value.btcWallet,
        'kda_wallet': value.kdaWallet,
        'ltc_wallet': value.ltcWallet,
        'ckb_wallet': value.ckbWallet,
        'kas_wallet': value.kasWallet,
        'doge_wallet': value.dogeWallet,
        'email_notification': value.emailNotification,
        'discord_notification': value.discordNotification,
        'newsletter_notification': value.newsletterNotification,
        'metadata': value.metadata,
        'info': JwtPayloadDeprecatedInfoToJSON(value.info),
    };
}

