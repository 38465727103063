import { ChangeEvent, FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { SingleToggleCard, Tag } from '@dynamic-labs/northstar';

import { useProvidersContext } from '../../../../../../../../../app/context/ProvidersContext';
import { OwnCredentialsForm } from '../OwnCredentialsForm';
import { OwnCredentialsAppleForm } from '../OwnCredentialsAppleForm';
import { DYNAMIC_SOCIAL_CREDENTIAL_PROVIDERS } from '../../../../../../../../../app/routes/Configurations/InformationCapture/data';
import { useEnvironmentsContext } from '../../../../../../../../../app/context/EnvironmentsContext';

import styles from './CredentialsToUseRadios.module.scss';

type CredentialsToUseRadiosProps = {
  provider: ProviderEnum;
};

export const CredentialsToUseRadios: FC<CredentialsToUseRadiosProps> = ({
  provider,
}) => {
  const { t } = useTranslation();

  const { getProviderValue, hasProviderChanged, onChangeProvider } =
    useProvidersContext();

  const { activeEnvironmentType } = useEnvironmentsContext();

  const allowToUseDynamicCredentials =
    DYNAMIC_SOCIAL_CREDENTIAL_PROVIDERS.includes(provider);

  const initialUsedDynamicCredentials = Boolean(
    !getProviderValue(provider, 'clientId') ||
      !getProviderValue(provider, 'clientSecret') ||
      !getProviderValue(provider, 'appleKeyId') ||
      !getProviderValue(provider, 'appleTeamId'),
  );

  const [useDynamicCredentials, setUseDynamicCredentials] = useState(
    activeEnvironmentType === 'sandbox' && allowToUseDynamicCredentials
      ? initialUsedDynamicCredentials
      : false,
  );
  const [credentialsAccordionState, setCredentialsAccordionState] = useState<
    string | undefined
  >(
    activeEnvironmentType === 'sandbox' && allowToUseDynamicCredentials
      ? undefined
      : `useOwnCredentials-${provider}`,
  );

  const isProviderSaved = !hasProviderChanged(provider);

  const handleUseDynamicCredentials = (
    e: ChangeEvent<HTMLInputElement>,
    type: 'dynamic' | 'own',
  ) => {
    if (type === 'dynamic' && e.target.checked) {
      setUseDynamicCredentials(true);
      setCredentialsAccordionState(undefined);

      onChangeProvider(provider, 'clientId', '');
      onChangeProvider(provider, 'clientSecret', '');
      onChangeProvider(provider, 'appleKeyId', '');
      onChangeProvider(provider, 'appleTeamId', '');

      return;
    }

    setCredentialsAccordionState(`useOwnCredentials-${provider}`);
    setUseDynamicCredentials(false);
  };

  const isApple = provider === ProviderEnum.Apple;

  return (
    <>
      {allowToUseDynamicCredentials && activeEnvironmentType === 'sandbox' && (
        <SingleToggleCard
          accordionKey={`useDynamicCredentials-${provider}`}
          title={t(
            'v2.page.log_in_methods.social.drawer.credentials_set_up.dynamic.title',
          )}
          description={t(
            'v2.page.log_in_methods.social.drawer.credentials_set_up.dynamic.description',
          )}
          allowExpand={false}
          Tags={[
            {
              Tag: (
                <Tag
                  text={t(
                    'v2.page.log_in_methods.social.drawer.credentials_set_up.dynamic.sandbox_only_badge',
                  )}
                  color='gray'
                  size='medium'
                  className={styles.tag}
                />
              ),
              id: 'sandbox',
            },
          ]}
          inputProps={{
            checked: useDynamicCredentials,
            id: `useDynamicCredentials-${provider}`,
            isSaved: isProviderSaved,
            onChange: (e) => handleUseDynamicCredentials(e, 'dynamic'),
            type: 'radio',
          }}
        />
      )}

      <SingleToggleCard
        accordionKey={`useOwnCredentials-${provider}`}
        title={t(
          'v2.page.log_in_methods.social.drawer.credentials_set_up.own.title',
        )}
        description={t(
          'v2.page.log_in_methods.social.drawer.credentials_set_up.own.description',
        )}
        allowExpand={!useDynamicCredentials}
        inputProps={{
          checked: !useDynamicCredentials,
          id: `useOwnCredentials-${provider}`,
          isSaved: isProviderSaved,
          onChange: (e) => handleUseDynamicCredentials(e, 'own'),
          type: 'radio',
        }}
        onValueChange={(value) => setCredentialsAccordionState(value)}
        value={useDynamicCredentials ? undefined : credentialsAccordionState}
      >
        <div className={styles.content}>
          {isApple ? (
            <OwnCredentialsAppleForm provider={provider} />
          ) : (
            <OwnCredentialsForm provider={provider} />
          )}
        </div>
      </SingleToggleCard>
    </>
  );
};
