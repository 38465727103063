import { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { Trans, useTranslation } from 'react-i18next';

import { InfoIcon } from '@dynamic-labs/sdk-react-core';

import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { TrialChip } from '../../../../components/TrialChip';
import { Typography } from '../../../../components/Typography';
import { Tooltip } from '../../../../components/Tooltip';
import Button from '../../../../components/Button';
import { openWindowUrl } from '../../../../utils/constants/defaults';
import { useSubscriptionLock } from '../../../../hooks/useSubscriptionLock';
import { useSubscriptionContext } from '../../../../context/SubscriptionContext';
import { rightSidebarBottomLinks } from '../../utils';
import { SandboxProgress } from '../SandboxProgress/SandboxProgress';

import styles from './Sidebar.module.css';

type Props = {
  currentSandboxStep: number;
  isLoading: boolean;
  sandboxMaxStepsCount: number;
};

export const Sidebar = ({
  sandboxMaxStepsCount,
  currentSandboxStep,
  isLoading,
}: Props) => {
  const { enableBillingInDashboard } = useFlags();
  const { checks, subscription, upgradeToAdvancePlan, isUpgrading } =
    useSubscriptionContext();
  const { shouldShowTrialChip } = useSubscriptionLock();
  const { t } = useTranslation();
  const { docsUrl } = useEnvironmentsContext();

  const planDescriptionText = useMemo<string>(() => {
    if (checks.isFreePlan) {
      return t('overview.quick_help.onboarding.free_plan_label');
    }
    if (checks.isEnterprisePlan) {
      return t('overview.quick_help.onboarding.enterprise_plan_label');
    }

    if (checks.isStandardPlan) {
      return subscription?.inTrial
        ? t('overview.quick_help.onboarding.standard_plan_trial_label')
        : t('overview.quick_help.onboarding.standard_plan_label');
    }

    return subscription?.inTrial
      ? t('overview.quick_help.onboarding.advanced_plan_trial_label')
      : t('overview.quick_help.onboarding.advanced_plan_label');
  }, [checks, subscription]);

  return (
    <aside className={styles['right-sidebar']}>
      <div className={styles.quickhelp__wrapper}>
        <div>
          <div className={styles['onboarding-progress__container']}>
            {!isLoading && (
              <>
                <div className={styles['onboarding-progress__head']}>
                  <SandboxProgress
                    maxSteps={sandboxMaxStepsCount}
                    activeStep={currentSandboxStep}
                  />
                </div>
                {enableBillingInDashboard && (
                  <div className={styles['onboarding-progress__body']}>
                    {shouldShowTrialChip && (
                      <TrialChip
                        small
                        daysLeft={subscription?.trialDaysLeft || 0}
                      />
                    )}
                    <div className={styles['onboarding-progress__info']}>
                      <Typography variant='paragraph-2' weight='medium'>
                        {planDescriptionText}
                      </Typography>
                      {checks.isFreePlan && (
                        <Tooltip
                          className='max-w-[200px]'
                          tooltipText={[
                            t('overview.quick_help.onboarding.tooltip_message'),
                          ]}
                        >
                          <InfoIcon />
                        </Tooltip>
                      )}
                    </div>
                    {!checks.isEnterprisePlan &&
                      (checks.isFreePlan ? (
                        <Button
                          variant='secondary'
                          onClick={upgradeToAdvancePlan}
                          testId='upgrade-plan-btn'
                          disabled={isUpgrading}
                        >
                          {t('subscription.upgrade_modal.confirmBtn')}
                        </Button>
                      ) : (
                        <Button
                          variant='secondary'
                          onClick={() =>
                            openWindowUrl(subscription?.billingPortalUrl)
                          }
                          testId='manage-subscription-btn'
                        >
                          {t(
                            'overview.quick_help.onboarding.manage_subscription_button',
                          )}
                        </Button>
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles['quickhelp__book-a-meeting']}>
            <Typography
              variant='paragraph-1'
              weight='regular'
              className={styles['book-a-meeting__paragraph']}
            >
              <Trans i18nKey='overview.quick_help.pairing.content' />
            </Typography>
            <Typography
              weight='medium'
              variant='subtitle'
              className={styles['book-a-meeting__subtitle']}
            >
              <Trans i18nKey='overview.quick_help.pairing.title' />
            </Typography>
            <a
              href='https://calendly.com/engineering-dynamic/engineering-1-1-pairing'
              target='_blank'
              className={styles['book-a-meeting__button']}
              rel='noreferrer'
            >
              <Trans i18nKey='overview.quick_help.pairing.button_text' />
            </a>
          </div>
        </div>
        <div className={styles['quickhelp__bottom-links']}>
          {rightSidebarBottomLinks(docsUrl).map((item) => (
            <div
              className={styles['quickhelp__bottom-links--wrapper']}
              key={`link-${item.id}`}
            >
              <Typography className={styles['quickhelp__bottom-links--label']}>
                {item.label}
              </Typography>
              <Button
                variant='secondary'
                leading={item.buttonIcon}
                onClick={() => openWindowUrl(item.url)}
              >
                <Typography variant='paragraph-1' weight='bold'>
                  {item.buttonText}
                </Typography>
              </Button>
            </div>
          ))}
        </div>
      </div>
    </aside>
  );
};
