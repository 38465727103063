import {
  CosmosIcon,
  OsmosisIcon,
  AxelarIcon,
  StarknetIcon,
} from '@dynamic-labs/iconic';

import { ReactComponent as ArbitrumIcon } from '../../assets/networks/arbitrum.svg';
import { ReactComponent as AuroraIcon } from '../../assets/networks/aurora.svg';
import { ReactComponent as BnbIcon } from '../../assets/networks/bnb.svg';
import { ReactComponent as BaseIcon } from '../../assets/networks/base.svg';
import { ReactComponent as EthereumIcon } from '../../assets/networks/eth.svg';
import { ReactComponent as GoerliIcon } from '../../assets/networks/goerli.svg';
import { ReactComponent as SepoliaIcon } from '../../assets/networks/sepolia.svg';
import { ReactComponent as MissingIcon } from '../../assets/networks/missing-icon.svg';
import { ReactComponent as OptimismIcon } from '../../assets/networks/optimism.svg';
import { ReactComponent as PolygonIcon } from '../../assets/networks/polygon.svg';
import { ReactComponent as PalmIcon } from '../../assets/networks/palm.svg';
import { ReactComponent as GnosisIcon } from '../../assets/networks/gnosis.svg';
import { ReactComponent as SolanaIcon } from '../../assets/networks/solana.svg';
import { ReactComponent as AvaxIcon } from '../../assets/networks/avax.svg';
import { ReactComponent as CeloIcon } from '../../assets/networks/celo.svg';
import { ReactComponent as ChilizIcon } from '../../assets/networks/chiliz.svg';
import { ReactComponent as FantomIcon } from '../../assets/networks/fantom.svg';
import { ReactComponent as ZkSyncIcon } from '../../assets/networks/zkSync.svg';
import { ReactComponent as CronosIcon } from '../../assets/networks/cronos.svg';
import { ReactComponent as ScrollIcon } from '../../assets/networks/scroll.svg';
import { ReactComponent as MoonbeamIcon } from '../../assets/networks/moonbeam.svg';
import { ReactComponent as ZoraIcon } from '../../assets/networks/zora.svg';
import { ReactComponent as EonIcon } from '../../assets/networks/eon.svg';
import { ReactComponent as BeraChainIcon } from '../../assets/networks/berachain.svg';
import { ReactComponent as FlowIcon } from '../../assets/networks/flow.svg';
import { ReactComponent as DegenIcon } from '../../assets/networks/degen.svg';
import { ReactComponent as ModeIcon } from '../../assets/networks/mode.svg';
import { ReactComponent as BlastIcon } from '../../assets/networks/blast.svg';
import { ReactComponent as OasisSapphireIcon } from '../../assets/networks/sapphire.svg';
import { ReactComponent as RootIcon } from '../../assets/networks/root.svg';
import { ReactComponent as ShardeumIcon } from '../../assets/networks/shardeum.svg';
import { ReactComponent as MorphIcon } from '../../assets/networks/morph.svg';

export type Icon = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

export const networkIconMap: Record<number, Icon> = {
  1: EthereumIcon,
  10: OptimismIcon,
  100: GnosisIcon,
  101: SolanaIcon,
  11155111: SepoliaIcon,
  11155420: OptimismIcon,
  11297108109: PalmIcon,
  1284: MoonbeamIcon,
  1287: MoonbeamIcon,
  1313161554: AuroraIcon,
  137: PolygonIcon,
  1663: EonIcon,
  17000: MorphIcon,
  204: BnbIcon,
  23294: OasisSapphireIcon,
  25: CronosIcon,
  250: FantomIcon,
  280: ZkSyncIcon,
  2810: MorphIcon,
  300: ZkSyncIcon,
  324: ZkSyncIcon,
  338: CronosIcon,
  34443: ModeIcon,
  401: CosmosIcon,
  402: AxelarIcon,
  403: OsmosisIcon,
  410: StarknetIcon,
  411: StarknetIcon,
  412: StarknetIcon,
  420: OptimismIcon,
  42161: ArbitrumIcon,
  421613: ArbitrumIcon,
  421614: ArbitrumIcon,
  42220: CeloIcon,
  43114: AvaxIcon,
  5: GoerliIcon,
  534351: ScrollIcon,
  534352: ScrollIcon,
  56: BnbIcon,
  5611: BnbIcon,
  646: FlowIcon,
  666666666: DegenIcon,
  7332: EonIcon,
  7668: RootIcon,
  7777777: ZoraIcon,
  80001: PolygonIcon,
  80002: PolygonIcon,
  80084: BeraChainIcon,
  80085: BeraChainIcon,
  8082: ShardeumIcon,
  81457: BlastIcon,
  8453: BaseIcon,
  84531: BaseIcon,
  84532: BaseIcon,
  88888: ChilizIcon,
  919: ModeIcon,
  999999999: ZoraIcon,
};
export const getNetworkIcon = (networkId: number) =>
  networkIconMap[networkId] ?? MissingIcon;
