/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectSettingsSdkWalletConnect
 */
export interface ProjectSettingsSdkWalletConnect {
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsSdkWalletConnect
     */
    projectId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsSdkWalletConnect
     */
    v2Enabled?: boolean;
}

export function ProjectSettingsSdkWalletConnectFromJSON(json: any): ProjectSettingsSdkWalletConnect {
    return ProjectSettingsSdkWalletConnectFromJSONTyped(json, false);
}

export function ProjectSettingsSdkWalletConnectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsSdkWalletConnect {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'v2Enabled': !exists(json, 'v2Enabled') ? undefined : json['v2Enabled'],
    };
}

export function ProjectSettingsSdkWalletConnectToJSON(value?: ProjectSettingsSdkWalletConnect | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectId': value.projectId,
        'v2Enabled': value.v2Enabled,
    };
}

