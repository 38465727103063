/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    BulkUserCreateResponse,
    BulkUserCreateResponseFromJSON,
    BulkUserCreateResponseToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    ForbiddenWithErrorAndPayload,
    ForbiddenWithErrorAndPayloadFromJSON,
    ForbiddenWithErrorAndPayloadToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    InternalUserFields,
    InternalUserFieldsFromJSON,
    InternalUserFieldsToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
    UserOauthAccessTokenResponse,
    UserOauthAccessTokenResponseFromJSON,
    UserOauthAccessTokenResponseToJSON,
    UserResponse,
    UserResponseFromJSON,
    UserResponseToJSON,
    UserSearchFilterParams,
    UserSearchFilterParamsFromJSON,
    UserSearchFilterParamsToJSON,
    UsersResponse,
    UsersResponseFromJSON,
    UsersResponseToJSON,
} from '../models';

export interface BulkCreateUserRequest {
    environmentId: string;
    internalUserFields: Array<InternalUserFields>;
    upsert?: boolean;
}

export interface CreateUserRequest {
    environmentId: string;
    internalUserFields: InternalUserFields;
}

export interface DeleteUserRequest {
    userId: string;
}

export interface GetEnvironmentUsersRequest {
    environmentId: string;
    filter?: UserSearchFilterParams;
    orderBy?: string;
    offset?: number;
    limit?: number;
}

export interface GetUserRequest {
    userId: string;
}

export interface GetUserOauthAccessTokenRequest {
    oauthAccountId: string;
}

export interface RevokeUserSessionsRequest {
    userId: string;
}

export interface UpdateUserRequest {
    userId: string;
    internalUserFields: InternalUserFields;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Creates many new users
     */
    async bulkCreateUserRaw(requestParameters: BulkCreateUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BulkUserCreateResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling bulkCreateUser.');
        }

        if (requestParameters.internalUserFields === null || requestParameters.internalUserFields === undefined) {
            throw new runtime.RequiredError('internalUserFields','Required parameter requestParameters.internalUserFields was null or undefined when calling bulkCreateUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.upsert !== undefined) {
            queryParameters['upsert'] = requestParameters.upsert;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/users/bulk`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.internalUserFields.map(InternalUserFieldsToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkUserCreateResponseFromJSON(jsonValue));
    }

    /**
     * Creates many new users
     */
    async bulkCreateUser(requestParameters: BulkCreateUserRequest, initOverrides?: RequestInit): Promise<BulkUserCreateResponse> {
        const response = await this.bulkCreateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new user
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createUser.');
        }

        if (requestParameters.internalUserFields === null || requestParameters.internalUserFields === undefined) {
            throw new runtime.RequiredError('internalUserFields','Required parameter requestParameters.internalUserFields was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/users`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InternalUserFieldsToJSON(requestParameters.internalUserFields),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
    }

    /**
     * Creates a new user
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit): Promise<UserResponse> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete user
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete user
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteUserRaw(requestParameters, initOverrides);
    }

    /**
     * Get all users for an environment
     */
    async getEnvironmentUsersRaw(requestParameters: GetEnvironmentUsersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UsersResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEnvironmentUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/users`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersResponseFromJSON(jsonValue));
    }

    /**
     * Get all users for an environment
     */
    async getEnvironmentUsers(requestParameters: GetEnvironmentUsersRequest, initOverrides?: RequestInit): Promise<UsersResponse> {
        const response = await this.getEnvironmentUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a user by Id
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
    }

    /**
     * Get a user by Id
     */
    async getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit): Promise<UserResponse> {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the access token for a user OAuth account
     */
    async getUserOauthAccessTokenRaw(requestParameters: GetUserOauthAccessTokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserOauthAccessTokenResponse>> {
        if (requestParameters.oauthAccountId === null || requestParameters.oauthAccountId === undefined) {
            throw new runtime.RequiredError('oauthAccountId','Required parameter requestParameters.oauthAccountId was null or undefined when calling getUserOauthAccessToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/oauthAccounts/{oauthAccountId}/accessToken`.replace(`{${"oauthAccountId"}}`, encodeURIComponent(String(requestParameters.oauthAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOauthAccessTokenResponseFromJSON(jsonValue));
    }

    /**
     * Get the access token for a user OAuth account
     */
    async getUserOauthAccessToken(requestParameters: GetUserOauthAccessTokenRequest, initOverrides?: RequestInit): Promise<UserOauthAccessTokenResponse> {
        const response = await this.getUserOauthAccessTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Revoke sessions by user ID
     */
    async revokeUserSessionsRaw(requestParameters: RevokeUserSessionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling revokeUserSessions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}/sessions/revoke`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Revoke sessions by user ID
     */
    async revokeUserSessions(requestParameters: RevokeUserSessionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.revokeUserSessionsRaw(requestParameters, initOverrides);
    }

    /**
     * Update a user
     */
    async updateUserRaw(requestParameters: UpdateUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUser.');
        }

        if (requestParameters.internalUserFields === null || requestParameters.internalUserFields === undefined) {
            throw new runtime.RequiredError('internalUserFields','Required parameter requestParameters.internalUserFields was null or undefined when calling updateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InternalUserFieldsToJSON(requestParameters.internalUserFields),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
    }

    /**
     * Update a user
     */
    async updateUser(requestParameters: UpdateUserRequest, initOverrides?: RequestInit): Promise<UserResponse> {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
