/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MFADeviceType,
    MFADeviceTypeFromJSON,
    MFADeviceTypeFromJSONTyped,
    MFADeviceTypeToJSON,
} from './MFADeviceType';

/**
 * 
 * @export
 * @interface MFADevice
 */
export interface MFADevice {
    /**
     * 
     * @type {MFADeviceType}
     * @memberof MFADevice
     */
    type?: MFADeviceType;
    /**
     * Whether or not the user has a verified this MFA Device
     * @type {boolean}
     * @memberof MFADevice
     */
    verified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MFADevice
     */
    id?: string;
    /**
     * The date and time the MFA device was created
     * @type {Date}
     * @memberof MFADevice
     */
    createdAt?: Date;
    /**
     * Whether or not this is the default MFA device for the user
     * @type {boolean}
     * @memberof MFADevice
     */
    _default?: boolean;
}

export function MFADeviceFromJSON(json: any): MFADevice {
    return MFADeviceFromJSONTyped(json, false);
}

export function MFADeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): MFADevice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : MFADeviceTypeFromJSON(json['type']),
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        '_default': !exists(json, 'default') ? undefined : json['default'],
    };
}

export function MFADeviceToJSON(value?: MFADevice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': MFADeviceTypeToJSON(value.type),
        'verified': value.verified,
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'default': value._default,
    };
}

