/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FarcasterSignInRequest
 */
export interface FarcasterSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof FarcasterSignInRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof FarcasterSignInRequest
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof FarcasterSignInRequest
     */
    nonce: string;
    /**
     * The message to be signed
     * @type {string}
     * @memberof FarcasterSignInRequest
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FarcasterSignInRequest
     */
    signature: string;
    /**
     * Optional captcha token to verify that the user is not a bot
     * @type {string}
     * @memberof FarcasterSignInRequest
     */
    captchaToken?: string;
}

export function FarcasterSignInRequestFromJSON(json: any): FarcasterSignInRequest {
    return FarcasterSignInRequestFromJSONTyped(json, false);
}

export function FarcasterSignInRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FarcasterSignInRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'domain': json['domain'],
        'nonce': json['nonce'],
        'message': json['message'],
        'signature': json['signature'],
        'captchaToken': !exists(json, 'captchaToken') ? undefined : json['captchaToken'],
    };
}

export function FarcasterSignInRequestToJSON(value?: FarcasterSignInRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'domain': value.domain,
        'nonce': value.nonce,
        'message': value.message,
        'signature': value.signature,
        'captchaToken': value.captchaToken,
    };
}

