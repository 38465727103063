/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SmsVerificationVerifyRequest
 */
export interface SmsVerificationVerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof SmsVerificationVerifyRequest
     */
    verificationUUID: string;
    /**
     * A 6-digit number
     * @type {string}
     * @memberof SmsVerificationVerifyRequest
     */
    verificationToken: string;
    /**
     * When provided, used to verify that a captcha is valid and get the success/failure result from the captcha provider server-side.
     * @type {string}
     * @memberof SmsVerificationVerifyRequest
     */
    captchaToken?: string;
}

export function SmsVerificationVerifyRequestFromJSON(json: any): SmsVerificationVerifyRequest {
    return SmsVerificationVerifyRequestFromJSONTyped(json, false);
}

export function SmsVerificationVerifyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmsVerificationVerifyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'verificationUUID': json['verificationUUID'],
        'verificationToken': json['verificationToken'],
        'captchaToken': !exists(json, 'captchaToken') ? undefined : json['captchaToken'],
    };
}

export function SmsVerificationVerifyRequestToJSON(value?: SmsVerificationVerifyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'verificationUUID': value.verificationUUID,
        'verificationToken': value.verificationToken,
        'captchaToken': value.captchaToken,
    };
}

