/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
    Webhook,
    WebhookFromJSON,
    WebhookToJSON,
    WebhookCreateRequest,
    WebhookCreateRequestFromJSON,
    WebhookCreateRequestToJSON,
    WebhookMessageRedeliveryResponse,
    WebhookMessageRedeliveryResponseFromJSON,
    WebhookMessageRedeliveryResponseToJSON,
    WebhookMessagesResponse,
    WebhookMessagesResponseFromJSON,
    WebhookMessagesResponseToJSON,
    WebhookUpdateRequest,
    WebhookUpdateRequestFromJSON,
    WebhookUpdateRequestToJSON,
    WebhooksResponse,
    WebhooksResponseFromJSON,
    WebhooksResponseToJSON,
} from '../models';

export interface CreateWebhookRequest {
    environmentId: string;
    webhookCreateRequest: WebhookCreateRequest;
}

export interface DeleteWebhookByIdRequest {
    environmentId: string;
    webhookId: string;
}

export interface GetWebhookRequest {
    environmentId: string;
    webhookId: string;
    includeSecret?: boolean;
}

export interface GetWebhookMessagesRequest {
    environmentId: string;
    webhookId: string;
    cursor?: string;
}

export interface GetWebhooksRequest {
    environmentId: string;
}

export interface RedeliverWebhookMessageRequest {
    environmentId: string;
    webhookId: string;
    messageId: string;
}

export interface UpdateWebhookByIdRequest {
    environmentId: string;
    webhookId: string;
    webhookUpdateRequest: WebhookUpdateRequest;
}

/**
 * 
 */
export class WebhooksApi extends runtime.BaseAPI {

    /**
     * Creates a new Webhooks for the project environment
     */
    async createWebhookRaw(requestParameters: CreateWebhookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Webhook>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createWebhook.');
        }

        if (requestParameters.webhookCreateRequest === null || requestParameters.webhookCreateRequest === undefined) {
            throw new runtime.RequiredError('webhookCreateRequest','Required parameter requestParameters.webhookCreateRequest was null or undefined when calling createWebhook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/environments/{environmentId}/webhooks`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebhookCreateRequestToJSON(requestParameters.webhookCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebhookFromJSON(jsonValue));
    }

    /**
     * Creates a new Webhooks for the project environment
     */
    async createWebhook(requestParameters: CreateWebhookRequest, initOverrides?: RequestInit): Promise<Webhook> {
        const response = await this.createWebhookRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the Webhook for an environment
     */
    async deleteWebhookByIdRaw(requestParameters: DeleteWebhookByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling deleteWebhookById.');
        }

        if (requestParameters.webhookId === null || requestParameters.webhookId === undefined) {
            throw new runtime.RequiredError('webhookId','Required parameter requestParameters.webhookId was null or undefined when calling deleteWebhookById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/webhooks/{webhookId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"webhookId"}}`, encodeURIComponent(String(requestParameters.webhookId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the Webhook for an environment
     */
    async deleteWebhookById(requestParameters: DeleteWebhookByIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteWebhookByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get the Webhook for an environment
     */
    async getWebhookRaw(requestParameters: GetWebhookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Webhook>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getWebhook.');
        }

        if (requestParameters.webhookId === null || requestParameters.webhookId === undefined) {
            throw new runtime.RequiredError('webhookId','Required parameter requestParameters.webhookId was null or undefined when calling getWebhook.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeSecret !== undefined) {
            queryParameters['includeSecret'] = requestParameters.includeSecret;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/webhooks/{webhookId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"webhookId"}}`, encodeURIComponent(String(requestParameters.webhookId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebhookFromJSON(jsonValue));
    }

    /**
     * Get the Webhook for an environment
     */
    async getWebhook(requestParameters: GetWebhookRequest, initOverrides?: RequestInit): Promise<Webhook> {
        const response = await this.getWebhookRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the Messages for an webhook
     */
    async getWebhookMessagesRaw(requestParameters: GetWebhookMessagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WebhookMessagesResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getWebhookMessages.');
        }

        if (requestParameters.webhookId === null || requestParameters.webhookId === undefined) {
            throw new runtime.RequiredError('webhookId','Required parameter requestParameters.webhookId was null or undefined when calling getWebhookMessages.');
        }

        const queryParameters: any = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/webhooks/{webhookId}/messages`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"webhookId"}}`, encodeURIComponent(String(requestParameters.webhookId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebhookMessagesResponseFromJSON(jsonValue));
    }

    /**
     * Get the Messages for an webhook
     */
    async getWebhookMessages(requestParameters: GetWebhookMessagesRequest, initOverrides?: RequestInit): Promise<WebhookMessagesResponse> {
        const response = await this.getWebhookMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the Webhooks for an environment
     */
    async getWebhooksRaw(requestParameters: GetWebhooksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WebhooksResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getWebhooks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/webhooks`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebhooksResponseFromJSON(jsonValue));
    }

    /**
     * Get the Webhooks for an environment
     */
    async getWebhooks(requestParameters: GetWebhooksRequest, initOverrides?: RequestInit): Promise<WebhooksResponse> {
        const response = await this.getWebhooksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Redeliver message for an webhook
     */
    async redeliverWebhookMessageRaw(requestParameters: RedeliverWebhookMessageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WebhookMessageRedeliveryResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling redeliverWebhookMessage.');
        }

        if (requestParameters.webhookId === null || requestParameters.webhookId === undefined) {
            throw new runtime.RequiredError('webhookId','Required parameter requestParameters.webhookId was null or undefined when calling redeliverWebhookMessage.');
        }

        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId','Required parameter requestParameters.messageId was null or undefined when calling redeliverWebhookMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/webhooks/{webhookId}/messages/{messageId}/redeliver`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"webhookId"}}`, encodeURIComponent(String(requestParameters.webhookId))).replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters.messageId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebhookMessageRedeliveryResponseFromJSON(jsonValue));
    }

    /**
     * Redeliver message for an webhook
     */
    async redeliverWebhookMessage(requestParameters: RedeliverWebhookMessageRequest, initOverrides?: RequestInit): Promise<WebhookMessageRedeliveryResponse> {
        const response = await this.redeliverWebhookMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the Webhook for an environment
     */
    async updateWebhookByIdRaw(requestParameters: UpdateWebhookByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Webhook>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling updateWebhookById.');
        }

        if (requestParameters.webhookId === null || requestParameters.webhookId === undefined) {
            throw new runtime.RequiredError('webhookId','Required parameter requestParameters.webhookId was null or undefined when calling updateWebhookById.');
        }

        if (requestParameters.webhookUpdateRequest === null || requestParameters.webhookUpdateRequest === undefined) {
            throw new runtime.RequiredError('webhookUpdateRequest','Required parameter requestParameters.webhookUpdateRequest was null or undefined when calling updateWebhookById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/environments/{environmentId}/webhooks/{webhookId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"webhookId"}}`, encodeURIComponent(String(requestParameters.webhookId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WebhookUpdateRequestToJSON(requestParameters.webhookUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebhookFromJSON(jsonValue));
    }

    /**
     * Update the Webhook for an environment
     */
    async updateWebhookById(requestParameters: UpdateWebhookByIdRequest, initOverrides?: RequestInit): Promise<Webhook> {
        const response = await this.updateWebhookByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
