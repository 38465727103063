/* eslint-disable react/jsx-no-useless-fragment */
import { Navigate } from 'react-router-dom';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { isMobile } from '@dynamic-labs/utils';
import {
  ExternalLinkIcon,
  useDynamicContext,
} from '@dynamic-labs/sdk-react-core';
import { MfaBackupCodeAcknowledgement } from '@dynamic-labs/sdk-api';

import { Logo, BookOpen, OnboardingIcon } from '../../../icons';
import { Typography } from '../../components/Typography';
import Button from '../../components/Button';
import { Icon } from '../../components/Icon';
import { useLDIdentify } from '../../hooks/useLDIdentify';

import styles from './Home.module.css';

export const Home = () => {
  const { isAuthenticated, setShowAuthFlow, user } = useDynamicContext();

  useLDIdentify();

  const pendingMfaAcknowledgement =
    user?.mfaBackupCodeAcknowledgement === MfaBackupCodeAcknowledgement.Pending;

  if (isAuthenticated && !pendingMfaAcknowledgement) {
    return <Navigate to='/dashboard/overview' />;
  }

  return (
    <div className={styles.home}>
      <header className={styles.header}>
        <Logo className={styles.logo} />
        {isMobile() ? (
          <a
            href='https://docs.dynamic.xyz/introduction/welcome'
            target='_blank'
            rel='noreferrer'
          >
            <Button
              variant='secondary'
              leading={<Icon size='medium' icon={<BookOpen />} />}
            >
              {t<string>('home.read_documentation_new')}
            </Button>
          </a>
        ) : (
          <div className={styles.headerButtons}>
            <a
              href='https://docs.dynamic.xyz/introduction/welcome'
              target='_blank'
              rel='noreferrer'
            >
              <Button
                variant='secondary'
                leading={<Icon size='medium' icon={<BookOpen />} />}
              >
                {t<string>('home.read_documentation')}
              </Button>
            </a>
            <Button onClick={() => setShowAuthFlow(true)} variant='secondary'>
              {t<string>('home.login')}
            </Button>
          </div>
        )}
      </header>
      <main className={styles.main}>
        <Typography className={styles.heading} as='h1'>
          {t<string>('home.heading_new')}
        </Typography>
        <Typography className={styles.subheading} as='h2'>
          {t<string>('home.subheading')}
        </Typography>
        <Typography variant='paragraph-3' className={styles.paragraph}>
          <Trans
            i18nKey={isMobile() ? 'home.mobile.paragraph' : 'home.paragraph'}
          />
        </Typography>
        <Button onClick={() => setShowAuthFlow(true)} large>
          {t<string>('home.button')}
        </Button>
      </main>
      <OnboardingIcon className={styles.onboardingIcon} />
      <footer className={styles.footer}>
        <a
          className={styles['marketing-page-link']}
          href='https://dynamic.xyz'
          target='_blank'
          rel='noreferrer'
        >
          <Button
            className={styles.footerLink}
            variant='link'
            suffix={<ExternalLinkIcon />}
          >
            {t<string>('home.dynamic_xyz')}
          </Button>
        </a>
        <span>© {new Date(Date.now()).getFullYear()} Dynamic Labs, Inc.</span>
        <a
          href='https://www.dynamic.xyz/privacy-policy'
          target='_blank'
          rel='noreferrer'
        >
          {t<string>('home.privacy_policy')}
        </a>
        <a
          href='https://www.dynamic.xyz/terms-conditions'
          target='_blank'
          rel='noreferrer'
        >
          {t<string>('home.terms_of_service')}
        </a>
      </footer>
    </div>
  );
};
