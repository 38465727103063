import { FC, ReactElement, ReactNode } from 'react';

/* eslint-disable-next-line import/no-namespace */
import * as Accordion from '@radix-ui/react-accordion';
import { AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import { Trans } from 'react-i18next';

import { BooleanInputProps } from '../BooleanInput/BooleanInput.types';
import { BooleanInput } from '../BooleanInput';
import { Icon } from '../Icon';
import { ChevronIcon } from '../../icons';
import { OpacityAnimation } from '../../animations';
import { Typography } from '../Typography';
import { AccordionContent } from '../AccordionContent';

import styles from './ToggleCard.module.scss';

type Modificators = {
  components?: Record<string, ReactElement>;
  i18nKey: string;
  values?: Record<string, string>;
};

/**
 * ToggleCardProps
 * - Icon: Optional icon to display on the left side of the card
 * - RightSideTags: Optional tags to display on the right side of the toggle card
 * - Tags: Optional tags to display on the right side of the card title
 * - allowExpand: Optional boolean to allow the card to expand
 * - children: Optional content to display when the card is expanded
 * - description: Optional description to display under the card title
 * - inputProps: Optional props to pass to the BooleanInput component if provided input will be rendered
 * - notification: Optional boolean to display a notification dot on the right side of the card
 * - title: Required title of the card
 * - accordionKey: Required prop of the card to be used as an accordion key (value attribute)
 * - onCardClick: Optional function to be called when the card is clicked when allowExpand is false
 * - CustomActionIcon: Optional custom icon to display on the right side of the card instead of Chevron
 * - titleModificators: Optional i18n key to use for the title
 * - descriptionModificators: Optional i18n key to use for the description
 *
 * <strong> If you need formatted text from i18n use titleKey and descriptionKey </strong>
 */
export type ToggleCardProps = {
  Icon?: ReactElement;
  RightSideTags?: { Tag: ReactElement; id: string }[];
  Tags?: { Tag: ReactElement; id: string }[];
  accordionKey: string;
  allowExpand?: boolean;
  children?: ReactNode;
  customActionIcon?: { Icon: ReactElement; className?: string };
  description?: string;
  descriptionModificators?: Modificators;
  inputProps?: Omit<BooleanInputProps, 'label'>;
  notification?: boolean;
  onCardClick?: VoidFunction;
  title?: string;
  titleModificators?: Modificators;
};

/**
 * <strong>Use it only when wrapped with Accordion.Root to create multiple toggle cards.</strong>
 *
 * Use this toggle card to display a card with an optional icon, title, description, and content.
 */
export const ToggleCard: FC<ToggleCardProps> = ({
  children,
  inputProps,
  title,
  allowExpand = true,
  notification,
  description,
  Icon: icon,
  Tags,
  RightSideTags,
  accordionKey,
  onCardClick,
  customActionIcon,
  titleModificators,
  descriptionModificators,
}) => (
  <div className={styles.card}>
    <Accordion.Item
      value={allowExpand ? accordionKey : ''}
      className={styles.section}
      data-allowexpand={allowExpand}
    >
      <div className={styles.header}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {inputProps && <BooleanInput {...inputProps} />}

        <Accordion.Trigger
          className={classNames(styles.trigger, {
            [styles['trigger--no-pointer']]: !allowExpand && !onCardClick,
            [styles['trigger--with-description']]: description,
          })}
          onClick={!allowExpand ? onCardClick : undefined}
          data-testid='toggle-card-trigger'
        >
          <div className={styles.label}>
            {icon && <Icon icon={icon} size={28} className={styles.icon} />}

            <div className={styles.text}>
              <div className={styles.titleWithTags}>
                {(title || titleModificators) && (
                  <Typography variant='paragraph-2' weight='bold'>
                    {titleModificators ? (
                      <Trans
                        i18nKey={titleModificators.i18nKey as any}
                        values={titleModificators.values}
                        components={titleModificators.components}
                      />
                    ) : (
                      title
                    )}
                  </Typography>
                )}

                <AnimatePresence>
                  {Tags && (
                    <OpacityAnimation>
                      <div className={styles.tags}>
                        {Tags.map((tag) => (
                          <span key={tag.id}>{tag.Tag}</span>
                        ))}
                      </div>
                    </OpacityAnimation>
                  )}
                </AnimatePresence>
              </div>

              {(description || descriptionModificators) && (
                <Typography
                  variant='paragraph-1'
                  weight='regular'
                  color='gray-1'
                >
                  {descriptionModificators ? (
                    <Trans
                      i18nKey={descriptionModificators.i18nKey as any}
                      values={descriptionModificators.values}
                      components={descriptionModificators.components}
                    />
                  ) : (
                    description
                  )}
                </Typography>
              )}
            </div>
          </div>

          <>
            <AnimatePresence>
              {notification && (
                <OpacityAnimation>
                  <span
                    className={styles.notification}
                    data-testid='toggle-card-notification'
                  />
                </OpacityAnimation>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {RightSideTags && (
                <OpacityAnimation>
                  {RightSideTags.map((tag) => (
                    <span key={tag.id}>{tag.Tag}</span>
                  ))}
                </OpacityAnimation>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {(allowExpand || onCardClick) && (
                <OpacityAnimation>
                  <Icon
                    icon={
                      customActionIcon ? customActionIcon.Icon : <ChevronIcon />
                    }
                    size={20}
                    className={classNames(
                      styles.dropdownIcon,
                      {
                        [styles['dropdownIcon--clickable']]:
                          // add this only when onCardClick is provided
                          !allowExpand && onCardClick && !customActionIcon,
                      },
                      customActionIcon?.className,
                    )}
                  />
                </OpacityAnimation>
              )}
            </AnimatePresence>
          </>
        </Accordion.Trigger>
      </div>

      <AccordionContent className={styles.content}>{children}</AccordionContent>
    </Accordion.Item>
  </div>
);
