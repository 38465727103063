import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';

import { useUpdateProjectSettings } from '@dynamic-labs/redcoast-query';

import { PageHeader } from '../../components/PageHeader';
import { useSaveDynamicSectionProvider } from '../EmbeddedWalletsPage/Sections/DynamicSection/useSaveDynamicSectionProvider';
import { SaveBanner } from '../../components/SaveBanner';

import { DeeplinkUrlRow } from './DeeplinkUrlRow';
import styles from './Security.module.scss';
import { JwtExpirationRow } from './JwtExpirationRow';

export const Security: FC = () => {
  const { t } = useTranslation();

  const { shouldShowSaveBanner, handleSave, handleReset } =
    useSaveDynamicSectionProvider();
  const { isLoading } = useUpdateProjectSettings();

  return (
    <section className={styles.wrapper}>
      <PageHeader
        title={t('v2.page.security.title')}
        description={t('v2.page.security.description')}
      />

      <DeeplinkUrlRow />
      <JwtExpirationRow />
      <AnimatePresence mode='wait'>
        {shouldShowSaveBanner && (
          <SaveBanner
            handleOnSave={handleSave}
            handleOnCancel={handleReset}
            isLoading={isLoading}
          />
        )}
      </AnimatePresence>
    </section>
  );
};
