import { FC, PropsWithChildren, ReactElement } from 'react';

import { AnimatePresence } from 'framer-motion';
import classNames from 'classnames';

import {
  XIcon,
  Icon,
  IconButton,
  TextButton,
  Typography,
} from '@dynamic-labs/northstar';

import Portal from '../../../app/components/Portal';

import styles from './SideDrawer.module.scss';

type SideDrawerProps = {
  Icon?: ReactElement;
  className?: string;
  description?: string;
  docs?: {
    text: string;
    url: string;
  };
  handleClose: VoidFunction;
  isOpen: boolean;
  title: string;
};

export const SideDrawer: FC<PropsWithChildren<SideDrawerProps>> = ({
  isOpen,
  handleClose,
  title,
  description,
  docs,
  Icon: icon,
  children,
  className,
}) => (
  <AnimatePresence>
    {isOpen && (
      <Portal
        handleClose={handleClose}
        className={classNames(styles.portal, className)}
      >
        <div className={styles.wrapper}>
          <IconButton
            icon={<XIcon />}
            onClick={handleClose}
            size={24}
            className={styles['close-icon']}
          />

          {icon && <Icon icon={icon} size={40} className={styles.icon} />}

          <header className={styles.header}>
            <Typography variant='title'>{title}</Typography>
            {description && (
              <Typography variant='paragraph-3' weight='regular' color='gray-1'>
                {description}
              </Typography>
            )}
            {docs && (
              <TextButton
                as='a'
                href={docs.url}
                text={docs.text}
                color='primary-1'
              />
            )}
          </header>

          {children}
        </div>
      </Portal>
    )}
  </AnimatePresence>
);
