import { ComponentPropsWithoutRef, forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { ClassStyleProps } from '../../utils/types';

import styles from './Input.module.scss';

export type InputProps = Pick<
  ComponentPropsWithoutRef<'input'>,
  'id' | 'type' | 'onChange' | 'onBlur' | 'value'
> &
  ClassStyleProps & {
    Icon?: ReactElement;
    disabled?: boolean;
    error?: boolean;
    label?: string;
    // Use this prop to mask sensitive content from sending to datadog
    // https://docs.datadoghq.com/real_user_monitoring/session_replay/browser/privacy_options/#mask-mode
    maskDatadogValue?: boolean;
    name: string;
    optional?: boolean;
    optionalText?: string;
    padding?: 'small' | 'regular';
  };

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      className,
      style,
      disabled,
      maskDatadogValue,
      type,
      id,
      name,
      onBlur,
      onChange,
      error,
      value,
      optional = false,
      optionalText,
      Icon: icon,
      padding = 'regular',
    },
    ref,
  ) => (
    <div className={classNames(className, styles.wrapper)} style={style}>
      <input
        id={id || name}
        disabled={disabled}
        data-testid={`input-${id || name}`}
        data-dd-privacy={maskDatadogValue ? 'mask' : undefined}
        type={type}
        onBlur={onBlur}
        onChange={onChange}
        name={name}
        placeholder={label}
        ref={ref}
        value={value}
        className={classNames(
          styles.input,
          styles[`input--padding-${padding}`],
          {
            [styles['input--with-label']]: label,
            [styles['input--error']]: error,
          },
        )}
      />

      <label
        className={classNames(styles.label, {
          [styles['label--error']]: error,
        })}
        htmlFor={id || name}
      >
        {label}
      </label>

      <label
        className={classNames(styles.right, {
          [styles['right--error']]: error,
        })}
        data-testid='input-right-side'
        htmlFor={id || name}
      >
        {optional && (
          <Typography
            variant='paragraph-1'
            className={styles.optional}
            color='inherit'
          >
            {optionalText || 'Optional'}
          </Typography>
        )}
        {icon && <Icon icon={icon} className={styles.icon} size={16} />}
      </label>
    </div>
  ),
);
