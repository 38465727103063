import { useEffect, useState } from 'react';

import { useHashLocation } from '../../../../hooks/useHashLocation';

export enum ModalHashLocations {
  AccessControl = 'accesscontrol',
  AccountAbstraction = 'accountabstraction',
  AdvancedWalletOptions = 'advancedwalletoptions',
  Algorand = 'algorand',
  Bitcoin = 'bitcoin',
  Chainalysis = 'chainalysis',
  Cosmos = 'cosmos',
  DynamicEmbeddedWallet = 'dynamic-embedded-wallet',
  DynamicEmbeddedWalletMFAOneTimeCode = 'dynamic-embedded-wallet-mfa-otc',
  DynamicEmbeddedWalletMFAPasskey = 'dynamic-embedded-wallet-mfa-passkey',
  // Providers modal but more user friendly
  EmailSocialSignIn = 'emailsocialsignin',
  // Providers modal but more user friendly
  EmbeddedWallets = 'embeddedwallets',
  Evm = 'evm',
  Flow = 'flow',
  HCaptcha = 'hcaptcha',
  InformationCapture = 'informationcapture',
  JwtExpiration = 'jwt-expiration',
  LogInMethodsEmailMethod = 'email-method',
  LogInMethodsMultiAsset = 'multi-asset',
  LogInMethodsMultiWallet = 'multi-wallet',
  LogInMethodsPhoneNumberMethod = 'phone-number-method',
  LogInMethodsWalletConnect = 'walletconnect',
  Mfa = 'mfa',
  MobileDeeplinkUrl = 'mobile-deeplink-urls',
  Onramp = 'onramp',
  Solana = 'solana',
  Starknet = 'starknet',
  ThirdPartyMagicLink = 'third-party-magic-link',
  WalletConnectV2 = 'walletconnectv2',
}

type UseModalHashLocations = {
  currentHash: ModalHashLocations | string;
};

export const useModalHashLocation = ({
  currentHash,
}: UseModalHashLocations) => {
  const [showModal, setShowModal] = useState(false);

  const { hash, setHash, removeHash } = useHashLocation({
    availableValues: (Object as any)
      .values(ModalHashLocations)
      .includes(currentHash)
      ? Object.values(ModalHashLocations)
      : undefined,
  });

  const handleShowModal = (value: boolean) => {
    if (value) {
      setHash(currentHash);
      return;
    }

    removeHash();
  };

  useEffect(() => {
    if (hash === currentHash) {
      setShowModal(true);
      return;
    }

    setShowModal(false);
  }, [currentHash, hash, setShowModal]);

  return {
    handleShowModal,
    showModal,
  };
};
