import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { InfoCircleIcon, Section } from '@dynamic-labs/northstar';

import { useSettingsContext } from '../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../app/context/EnvironmentsContext';

import styles from './UserInformationSection.module.scss';
import { KycFieldToggle } from './KycFieldToggle';

const kycFieldsToSkip = ['email', 'phoneNumber', 'social'];

type UserInformationSectionProps = {
  disabled: boolean;
};

const CUSTOM_FIELDS_DOCS =
  'https://docs.dynamic.xyz/api-reference/endpoints/environments/updateProjectSettings';

export const UserInformationSection: FC<UserInformationSectionProps> = ({
  disabled,
}) => {
  const { t } = useTranslation();

  const { settings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const availableKycSettings = settings[activeEnvironmentType].kyc
    .filter((field) => !kycFieldsToSkip.includes(field.name))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Section
      title={t('v2.page.log_in_methods.kyc.title')}
      description={t('v2.page.log_in_methods.kyc.description')}
      alerts={[
        {
          IconOverride: <InfoCircleIcon />,
          action: {
            as: 'a',
            href: CUSTOM_FIELDS_DOCS,
            text: t('v2.page.log_in_methods.kyc.custom_fields_alert.action'),
          },
          description: t(
            'v2.page.log_in_methods.kyc.custom_fields_alert.description',
          ),
          title: t('v2.page.log_in_methods.kyc.custom_fields_alert.title'),
          variant: 'advance',
        },
      ]}
      alertsAlignment='top'
    >
      <div className={styles.section}>
        {availableKycSettings.map((kycField) => (
          <KycFieldToggle
            key={kycField.name}
            field={kycField}
            disabled={disabled}
          />
        ))}
      </div>
    </Section>
  );
};
