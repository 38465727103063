/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OauthResultRequest
 */
export interface OauthResultRequest {
    /**
     * Temporary auth state for oauth2 access
     * @type {string}
     * @memberof OauthResultRequest
     */
    state: string;
}

export function OauthResultRequestFromJSON(json: any): OauthResultRequest {
    return OauthResultRequestFromJSONTyped(json, false);
}

export function OauthResultRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OauthResultRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
    };
}

export function OauthResultRequestToJSON(value?: OauthResultRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
    };
}

