import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { Input } from '@dynamic-labs/northstar';

import styles from './JwtTestField.module.scss';

export const JwtTestField = ({
  isEnterprisePlan,
}: {
  isEnterprisePlan: boolean;
}) => {
  const { t } = useTranslation();
  const [field] = useField('jwt');
  const placeholder = t('third_party_auth.check_jwt.placeholder');

  return (
    <div className={styles.jwtField}>
      <Input
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        id='jwt'
        label={placeholder}
        disabled={!isEnterprisePlan}
      />
    </div>
  );
};
