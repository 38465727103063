import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Section } from '@dynamic-labs/northstar';

import { SecurityMethodToggle } from './SecurityMethodToggle';
import styles from './MFARow.module.scss';

type MFARowProps = {
  disabled: boolean;
};

export const MFARow: FC<MFARowProps> = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <Section
      title={t('v2.page.embedded_wallets.dynamic_section.mfa.title')}
      description={t(
        'v2.page.embedded_wallets.dynamic_section.mfa.description',
      )}
    >
      <div className={styles.wrapper}>
        <SecurityMethodToggle method='passkey' disabled={disabled} />
        <SecurityMethodToggle method='email' disabled={disabled} />
      </div>
    </Section>
  );
};
