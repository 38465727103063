import React, { FC } from 'react';

import {
  ChainConfiguration,
  ChainEnum,
  ProviderEnum,
} from '@dynamic-labs/sdk-api';
import { Chain, ChainDropdownMenu } from '@dynamic-labs/northstar';

import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useProvidersContext } from '../../../../../../../app/context/ProvidersContext';

type DefaultChainDropdownProps = {
  disabled: boolean;
};

export const DefaultChainDropdown: FC<DefaultChainDropdownProps> = ({
  disabled,
}) => {
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, setSettings } = useSettingsContext();
  const { getProviderValue } = useProvidersContext();

  const ewChainConfiguration =
    settings[activeEnvironmentType]?.sdk?.embeddedWallets?.chainConfigurations;

  const isTurnkeyEnabled = Boolean(
    getProviderValue(ProviderEnum.Turnkey, 'enabledAt'),
  );

  const enabledChains: Chain[] =
    ewChainConfiguration
      ?.filter((config: ChainConfiguration) => config.enabled || config.primary)
      .map((config: ChainConfiguration) => ({
        chainName: config.name as ChainEnum,
      })) ?? [];

  const handlePrimaryChainChange = (chainName: ChainEnum) => {
    const chainConfigToUpdate = ewChainConfiguration?.find(
      (config: ChainConfiguration) => config.name === chainName,
    );

    if (!chainConfigToUpdate) return;

    // toggle off prev primary
    ewChainConfiguration?.forEach((c: ChainConfiguration) => {
      c.primary = false;
    });
    chainConfigToUpdate.primary = true;

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType].sdk.embeddedWallets,
            chainConfigurations: ewChainConfiguration,
          },
        },
      },
    });
  };

  const selectedChain = ewChainConfiguration?.find(
    (config: ChainConfiguration) => config.primary,
  )?.name as ChainEnum;

  return (
    <ChainDropdownMenu
      chains={enabledChains}
      selectedChain={selectedChain}
      setSelectedChain={handlePrimaryChainChange}
      zIndex={21}
      disabled={disabled || !isTurnkeyEnabled}
    />
  );
};
