import { useState } from 'react';

import { UserFilterableFieldsEnum, ChainEnum } from '@dynamic-labs/sdk-api';

export const useUsersSearchFilter = () => {
  const [usersSearchFilter, setUsersSearchFilter] =
    useState<UserFilterableFieldsEnum>(
      UserFilterableFieldsEnum.WalletPublicKey,
    );
  const [usersChainFilter, setUsersChainFilter] = useState<ChainEnum>();

  return {
    setUsersChainFilter,
    setUsersSearchFilter,
    usersChainFilter,
    usersSearchFilter,
  };
};
