import { ReactNode } from 'react';

import { t } from 'i18next';

import { TwitterIcon } from '@dynamic-labs/iconic';

import { BookOpen, SlackIcon } from '../../../../icons';
import { ROUTES } from '../../../components/Navigation/data';
import { Icon } from '../../../components/Icon';

export type QuickStartStep = {
  content?: ReactNode;
  id: number;
  isActive?: boolean;
  key: string;
  title: string;
};

export const rightSidebarBottomLinks = (
  docsUrl: string = ROUTES.documentation.toString(),
) => [
  {
    buttonIcon: <Icon size='medium' icon={<BookOpen />} />,
    buttonText: t('overview.quick_help.bottom_links.docs_button_text'),
    id: 1,
    label: t('overview.quick_help.bottom_links.docs_label'),
    url: docsUrl,
  },
  {
    buttonIcon: <Icon size='medium' icon={<SlackIcon />} />,
    buttonText: t('overview.quick_help.bottom_links.slack_button_text'),
    id: 2,
    label: t('overview.quick_help.bottom_links.slack_label'),
    url: 'https://www.dynamic.xyz/join-slack',
  },
  {
    buttonIcon: <Icon size='medium' icon={<TwitterIcon />} />,
    buttonText: t('overview.quick_help.bottom_links.twitter_button_text'),
    id: 3,
    label: t('overview.quick_help.bottom_links.twitter_label'),
    url: 'https://twitter.com/dynamic_xyz',
  },
];
