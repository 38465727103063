/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CoinbaseMpcWalletProperties,
    CoinbaseMpcWalletPropertiesFromJSON,
    CoinbaseMpcWalletPropertiesFromJSONTyped,
    CoinbaseMpcWalletPropertiesToJSON,
} from './CoinbaseMpcWalletProperties';
import {
    HardwareWalletEnum,
    HardwareWalletEnumFromJSON,
    HardwareWalletEnumFromJSONTyped,
    HardwareWalletEnumToJSON,
} from './HardwareWalletEnum';
import {
    HardwareWalletProperties,
    HardwareWalletPropertiesFromJSON,
    HardwareWalletPropertiesFromJSONTyped,
    HardwareWalletPropertiesToJSON,
} from './HardwareWalletProperties';
import {
    PasswordSourceTypeEnum,
    PasswordSourceTypeEnumFromJSON,
    PasswordSourceTypeEnumFromJSONTyped,
    PasswordSourceTypeEnumToJSON,
} from './PasswordSourceTypeEnum';
import {
    TurnkeyWalletProperties,
    TurnkeyWalletPropertiesFromJSON,
    TurnkeyWalletPropertiesFromJSONTyped,
    TurnkeyWalletPropertiesToJSON,
} from './TurnkeyWalletProperties';

/**
 * 
 * @export
 * @interface WalletProperties
 */
export interface WalletProperties {
    /**
     * 
     * @type {string}
     * @memberof WalletProperties
     */
    turnkeySubOrganizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletProperties
     */
    turnkeyPrivateKeyId?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletProperties
     */
    turnkeyHDWalletId?: string;
    /**
     * Whether or not the wallet has an authenticator (passkey, api key, etc) attached to it.
     * @type {boolean}
     * @memberof WalletProperties
     */
    isAuthenticatorAttached?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WalletProperties
     */
    turnkeyUserId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WalletProperties
     */
    isSessionKeyCompatible?: boolean;
    /**
     * 
     * @type {HardwareWalletEnum}
     * @memberof WalletProperties
     */
    hardwareWallet?: HardwareWalletEnum;
    /**
     * Dynamic pregenerated this wallet and stored the passcode
     * @type {boolean}
     * @memberof WalletProperties
     */
    claimed?: boolean;
    /**
     * 
     * @type {PasswordSourceTypeEnum}
     * @memberof WalletProperties
     */
    source?: PasswordSourceTypeEnum;
}

export function WalletPropertiesFromJSON(json: any): WalletProperties {
    return WalletPropertiesFromJSONTyped(json, false);
}

export function WalletPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): WalletProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'turnkeySubOrganizationId': !exists(json, 'turnkeySubOrganizationId') ? undefined : json['turnkeySubOrganizationId'],
        'turnkeyPrivateKeyId': !exists(json, 'turnkeyPrivateKeyId') ? undefined : json['turnkeyPrivateKeyId'],
        'turnkeyHDWalletId': !exists(json, 'turnkeyHDWalletId') ? undefined : json['turnkeyHDWalletId'],
        'isAuthenticatorAttached': !exists(json, 'isAuthenticatorAttached') ? undefined : json['isAuthenticatorAttached'],
        'turnkeyUserId': !exists(json, 'turnkeyUserId') ? undefined : json['turnkeyUserId'],
        'isSessionKeyCompatible': !exists(json, 'isSessionKeyCompatible') ? undefined : json['isSessionKeyCompatible'],
        'hardwareWallet': !exists(json, 'hardwareWallet') ? undefined : HardwareWalletEnumFromJSON(json['hardwareWallet']),
        'claimed': !exists(json, 'claimed') ? undefined : json['claimed'],
        'source': !exists(json, 'source') ? undefined : PasswordSourceTypeEnumFromJSON(json['source']),
    };
}

export function WalletPropertiesToJSON(value?: WalletProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'turnkeySubOrganizationId': value.turnkeySubOrganizationId,
        'turnkeyPrivateKeyId': value.turnkeyPrivateKeyId,
        'turnkeyHDWalletId': value.turnkeyHDWalletId,
        'isAuthenticatorAttached': value.isAuthenticatorAttached,
        'turnkeyUserId': value.turnkeyUserId,
        'isSessionKeyCompatible': value.isSessionKeyCompatible,
        'hardwareWallet': HardwareWalletEnumToJSON(value.hardwareWallet),
        'claimed': value.claimed,
        'source': PasswordSourceTypeEnumToJSON(value.source),
    };
}

