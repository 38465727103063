import { ChangeEventHandler, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '../../../../../components/Typography';
import { ControlFieldHeader } from '../../../../../components/ControlFieldHeader';

export type MFASecuritySettingsProps = {
  disabled: boolean;
  isRequireAtSignUpEnabled: boolean;
  toggleRequireAtSignUp: ChangeEventHandler<HTMLInputElement>;
};

export const MFASecuritySettings: FC<MFASecuritySettingsProps> = ({
  isRequireAtSignUpEnabled,
  toggleRequireAtSignUp,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <div className='flex items-start flex-col gap-1'>
      <Typography variant='paragraph-3' weight='medium'>
        {t('integrations.wallets.providers.mfa.security_settings.title')}
      </Typography>
      <Typography variant='paragraph-2' weight='regular' color='gray-1'>
        {t('integrations.wallets.providers.mfa.security_settings.subtitle')}
      </Typography>

      <div className='border border-cloud-2 px-4 rounded-xl divide-y flex-col flex mt-2 w-full'>
        <ControlFieldHeader
          disabled={disabled}
          handleToggleChange={toggleRequireAtSignUp}
          isInitialEnabled={isRequireAtSignUpEnabled}
          className='!gap-2'
          header={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <div className='flex gap-3 py-1 items-center'>
              <div className='flex flex-col items-start w-full'>
                <Typography variant='paragraph-2' weight='bold'>
                  {t(
                    'integrations.wallets.providers.mfa.security_settings.toggle.require_on_signup.label',
                  )}
                </Typography>

                <Typography
                  variant='paragraph-2'
                  weight='regular'
                  color='gray-1'
                >
                  {t(
                    'integrations.wallets.providers.mfa.security_settings.toggle.require_on_signup.subtitle',
                  )}
                </Typography>
              </div>
            </div>
          }
          toggleId='mfa_required_at_signup'
          toggleValue={isRequireAtSignUpEnabled}
          tooltipText={[
            t(
              'integrations.wallets.providers.mfa.security_settings.toggle.require_on_signup.aria_label',
            ),
          ]}
        />
      </div>
    </div>
  );
};
