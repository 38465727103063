import { t } from 'i18next';

export const columnsNames = {
  alias: t('users_management_table.alias'),
  all: t('users_management_table.all'),
  country: t('users_management_table.country'),
  email: t('users_management_table.email'),
  firstName: t('users_management_table.first_name'),
  id: t('users_management_table.user_id'),
  jobTitle: t('users_management_table.job_title'),
  lastName: t('users_management_table.last_name'),
  phoneNumber: t('users_management_table.phone_number'),
  team: t('users_management_table.team'),
  username: t('users_management_table.username'),
  wallet: t('users_management_table.wallet_name'),
  walletPublicKey: t('users_management_table.address'),
};
