export * from './regexp';
export { walletConnectorDefault, userInfoDefault } from './defaults';

export const ACTIVE_ORG_AND_PROJ = 'dynamic_dashboard_active_org_proj';

export const ACTIVE_ENV = 'dynamic_dashboard_active_env';

export const AUTH_TOKEN = 'dynamic_authentication_token';

export const CLOSED_INFO_BANNERS_MAP = 'dynamic_closed_info_banners_map';

export const QUICKSTART_ONBOARDING_STEP = 'dynamic_quickstart_onboarding_step';

export const EMBEDDED_WALLET_DOCS_URL =
  'https://docs.dynamic.xyz/embedded-wallets/passkeys#setting-things-up';

export const TERMS_AND_CONDITIONS_URL =
  'https://www.turnkey.com/files/terms-of-service.pdf';
