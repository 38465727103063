/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldType,
    CustomFieldTypeFromJSON,
    CustomFieldTypeFromJSONTyped,
    CustomFieldTypeToJSON,
} from './CustomFieldType';
import {
    CustomFieldValidationRules,
    CustomFieldValidationRulesFromJSON,
    CustomFieldValidationRulesFromJSONTyped,
    CustomFieldValidationRulesToJSON,
} from './CustomFieldValidationRules';
import {
    KycFieldType,
    KycFieldTypeFromJSON,
    KycFieldTypeFromJSONTyped,
    KycFieldTypeToJSON,
} from './KycFieldType';

/**
 * 
 * @export
 * @interface ProjectSettingsKyc
 */
export interface ProjectSettingsKyc {
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsKyc
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsKyc
     */
    required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsKyc
     */
    enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsKyc
     */
    unique: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsKyc
     */
    verify: boolean;
    /**
     * 
     * @type {KycFieldType}
     * @memberof ProjectSettingsKyc
     */
    type?: KycFieldType;
    /**
     * 
     * @type {CustomFieldValidationRules}
     * @memberof ProjectSettingsKyc
     */
    validationRules?: CustomFieldValidationRules;
    /**
     * 
     * @type {CustomFieldType}
     * @memberof ProjectSettingsKyc
     */
    validationType?: CustomFieldType;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsKyc
     */
    label?: string;
}

export function ProjectSettingsKycFromJSON(json: any): ProjectSettingsKyc {
    return ProjectSettingsKycFromJSONTyped(json, false);
}

export function ProjectSettingsKycFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsKyc {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'required': json['required'],
        'enabled': json['enabled'],
        'unique': json['unique'],
        'verify': json['verify'],
        'type': !exists(json, 'type') ? undefined : KycFieldTypeFromJSON(json['type']),
        'validationRules': !exists(json, 'validationRules') ? undefined : CustomFieldValidationRulesFromJSON(json['validationRules']),
        'validationType': !exists(json, 'validationType') ? undefined : CustomFieldTypeFromJSON(json['validationType']),
        'label': !exists(json, 'label') ? undefined : json['label'],
    };
}

export function ProjectSettingsKycToJSON(value?: ProjectSettingsKyc | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'required': value.required,
        'enabled': value.enabled,
        'unique': value.unique,
        'verify': value.verify,
        'type': KycFieldTypeToJSON(value.type),
        'validationRules': CustomFieldValidationRulesToJSON(value.validationRules),
        'validationType': CustomFieldTypeToJSON(value.validationType),
        'label': value.label,
    };
}

