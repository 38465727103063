import { useEffect, useState } from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import Moment from 'react-moment';
import moment from 'moment';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { useTranslation } from 'react-i18next';

import { Webhook } from '@dynamic-labs/sdk-api';

import {
  CheckIcon,
  CloseIcon,
  DropdownIcon,
  RefreshIcon,
} from '../../../../../icons';
import { Badge } from '../../../../components/Badge';
import { Typography } from '../../../../components/Typography';
import Button from '../../../../components/Button';
import { webhooksApi } from '../../../../services/api';
import { Toaster } from '../../../../components/Toaster';
import { Tooltip } from '../../../../components/Tooltip';

import styles from './MessageList.module.css';

const WebhookMessage = ({
  webhook,
  message,
}: {
  message: any;
  webhook: Webhook | undefined;
}) => {
  const { t } = useTranslation();
  const [showToaster, setShowToaster] = useState(false);
  const [isLoadingRedelivery, setIsLoadingRedelivery] = useState(false);

  useEffect(() => {
    if (!showToaster) {
      return;
    }
    const toasterTimer = setTimeout(() => {
      setShowToaster(false);
    }, 3000);
    return () => {
      clearTimeout(toasterTimer);
    };
  }, [showToaster]);

  const handleRedeliver = async () => {
    try {
      setIsLoadingRedelivery(true);

      await webhooksApi.redeliverWebhookMessage({
        environmentId: message.environmentId,
        messageId: message.request.body.messageId,
        webhookId: message.webhookId,
      });
      setShowToaster(true);
    } finally {
      setIsLoadingRedelivery(false);
    }
  };

  const formattedRequestHeaders = () => {
    if (message.status === 'failed') {
      return message?.request?.headers
        ? JSON.stringify(JSON.parse(message?.request?.headers), null, 2)
        : 'No headers';
    }
    return JSON.stringify(message?.request?.headers, null, 2);
  };

  return (
    <div>
      <AnimatePresence>
        {showToaster && (
          <Toaster
            variant='success'
            message='Message sent for redelivery'
            onExit={() => setShowToaster(false)}
          />
        )}
      </AnimatePresence>
      <div>
        <div className='flex justify-between mb-2 mt-2'>
          <div>
            <Typography variant='subtitle'>
              {t(
                'webhooks.webhook_detail_modal.message_response_section_label',
              )}
            </Typography>
            <Typography variant='paragraph-1' weight='medium' color='gray-1'>
              {t('webhooks.webhook_detail_modal.message_response_result')}
            </Typography>
            <Typography variant='paragraph-1' className='mb-2'>
              {message?.response.status}
            </Typography>
          </div>
          <Tooltip
            tooltipText={[
              t('webhooks.webhook_detail_modal.redeliver_button_tooltip_text'),
            ]}
            disableHover={webhook?.isEnabled}
          >
            <Button
              variant='secondary'
              disabled={!webhook?.isEnabled}
              onClick={handleRedeliver}
              loading={isLoadingRedelivery}
            >
              <RefreshIcon className='w-4 h-4 text-gray-2' />
              {t('webhooks.webhook_detail_modal.redeliver_button_text')}
            </Button>
          </Tooltip>
        </div>
        {message?.response?.headers && (
          <>
            <Typography variant='paragraph-1' weight='medium' color='gray-1'>
              {t('webhooks.webhook_detail_modal.message_response_headers')}
            </Typography>
            <div className='mb-4'>
              <SyntaxHighlighter
                language='json'
                style={coldarkDark}
                showLineNumbers
                customStyle={{
                  borderRadius: '5px',
                  fontSize: '0.7rem',
                  lineHeight: '1.25rem',
                  padding: '20px',
                }}
              >
                {JSON.stringify(message?.response?.headers, null, 2)}
              </SyntaxHighlighter>
            </div>
          </>
        )}
        <Typography variant='paragraph-1' weight='medium' color='gray-1'>
          {t('webhooks.webhook_detail_modal.message_response_body_label')}
        </Typography>
        <div className='mb-4'>
          <SyntaxHighlighter
            language='json'
            style={coldarkDark}
            showLineNumbers
            customStyle={{
              borderRadius: '5px',
              fontSize: '0.7rem',
              lineHeight: '1.25rem',
              padding: '20px',
            }}
          >
            {message.status === 'failed'
              ? message?.response?.statusText
              : JSON.stringify(message?.response?.data, null, 2)}
          </SyntaxHighlighter>
        </div>
        <Typography variant='subtitle'>
          {t('webhooks.webhook_detail_modal.message_request_section_label')}
        </Typography>
        <Typography variant='paragraph-1' weight='medium' color='gray-1'>
          {t('webhooks.webhook_detail_modal.message_request_headers')}
        </Typography>
        <div
          className='mb-4'
          style={{ marginBottom: '10px', whiteSpace: 'pre-line' }}
        >
          <SyntaxHighlighter
            language='json'
            style={coldarkDark}
            showLineNumbers
            customStyle={{
              borderRadius: '5px',
              fontSize: '0.7rem',
              lineHeight: '1.25rem',
              padding: '20px',
            }}
          >
            {formattedRequestHeaders()}
          </SyntaxHighlighter>
        </div>
        <Typography variant='paragraph-1' weight='medium' color='gray-1'>
          {t('webhooks.webhook_detail_modal.message_request_payload_label')}
        </Typography>
        <div
          className='mb-4'
          style={{ marginBottom: '10px', whiteSpace: 'pre-line' }}
        >
          <SyntaxHighlighter
            language='json'
            style={coldarkDark}
            showLineNumbers
            customStyle={{
              borderRadius: '5px',
              fontSize: '0.7rem',
              lineHeight: '1.25rem',
              padding: '20px',
            }}
          >
            {JSON.stringify(message?.request?.body, null, 2)}
          </SyntaxHighlighter>
        </div>
      </div>
    </div>
  );
};

export const MessageListItem = ({
  webhook,
  deliveryId,
  message,
  expanded,
  setExpanded,
}: {
  deliveryId: string | undefined;
  expanded: string;
  message: any;
  setExpanded: any;
  webhook: Webhook | undefined;
}) => {
  const isOpen = deliveryId === expanded;
  const isRedelivery = message?.request?.body?.redelivery;
  const startTimestamp = isRedelivery
    ? message.eventReceivedAt
    : message.eventCreatedAt;
  const duration = message?.deliveredAt
    ? moment
        .duration(moment(message?.deliveredAt).diff(startTimestamp))
        .asSeconds()
    : 'error';

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <AnimatePresence initial={false}>
      <button
        type='button'
        className='flex items-center justify-between w-full py-3 px-4'
        onClick={() => setExpanded(isOpen ? '' : deliveryId)}
      >
        <div className='flex items-center space-x-2'>
          <div className='flex items-center'>
            <div
              className={
                message.status === 'delivered'
                  ? styles.successIcon
                  : styles.failureIcon
              }
            >
              {message.status === 'delivered' ? (
                <CheckIcon className='text-green-1 h-4 w-4' />
              ) : (
                <CloseIcon className='text-red-1 h-3 w-3' />
              )}
            </div>
            <Typography variant='paragraph-1' weight='medium' color='gray-1'>
              {message?.eventId}
            </Typography>
          </div>
          <Badge
            variant='secondary'
            text={message?.request?.body?.eventName}
            size='small'
            className='ml-2 mr-2'
          />
          {isRedelivery && (
            <Badge variant='primary' text='redelivery' size='small' />
          )}
        </div>
        <div className='text-xs hidden'>{duration} seconds</div>
        <div className='flex items-center space-x-3'>
          <Typography variant='paragraph-1' weight='medium' color='gray-1'>
            <Moment format='h:mm:ss A'>{message?.deliveredAt}</Moment>
          </Typography>
          <DropdownIcon
            color='gray-2'
            className={`text-gray-2 ${isOpen ? '!rotate-180' : ''}`}
          />
        </div>
      </button>
      {isOpen && (
        <motion.section
          key='content'
          initial='collapsed'
          animate='open'
          exit='collapsed'
          variants={{
            collapsed: { height: 0, opacity: 0 },
            open: { height: 'auto', opacity: 1 },
          }}
          transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          <div className='px-3 py-1.5'>
            <WebhookMessage webhook={webhook} message={message} />
          </div>
        </motion.section>
      )}
    </AnimatePresence>
  );
};
