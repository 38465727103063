import { TFunction } from 'react-i18next';

import { OAuthAccount } from '@dynamic-labs/sdk-api';
import { SocialIcon } from '@dynamic-labs/iconic';

export const getSocialsTableColumns = (
  t: TFunction<'translation', undefined>,
) => [
  {
    Header: '#',
    accessor: (_row: OAuthAccount, i: number) => i + 1,
    className: 'max-w-8 w-2',
    id: 'index',
  },
  {
    Header: t('users_management_table.oAuthAccounts'),
    accessor: ({ provider }: OAuthAccount) => (
      <>
        <SocialIcon className='w-4 h-4' name={provider || ''} />
        {provider}
      </>
    ),
    className: 'flex gap-2 capitalize',
    id: 'wallet',
  },
];
