import { FC } from 'react';

import { Outlet, useMatch } from 'react-router-dom';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import {
  OpenBookIcon,
  QuestionMarkCircleIcon,
  TopBar,
} from '@dynamic-labs/northstar';

import { ROUTES } from '../../../app/components/Navigation/data';
import AddOrganizationModal from '../../../app/components/Navigation/OrganizationDropdown/AddOrganizationModal';
import AddProjectModal from '../../../app/components/Navigation/ProjectDropdown/AddProjectModal';
import { Navigation } from '../Navigation';
import { UpdateProjectModal } from '../../../app/routes/Admin/UpdateProjectModal';
import { useDashboardContext } from '../../../app/context/DashboardContext';
import {
  useEnvironmentsDropdown,
  useOrganizationsDropdown,
  useProjectsDropdown,
} from '../../hooks';
import { useSaveBannerContext } from '../../contexts/SaveBannerContext';
import { AddPaymentMethodInfoModal } from '../AddPaymentMethodInfoModal';

import styles from './DashboardLayout.module.scss';

const helpButtons = [
  {
    href: 'https://dynamic.xyz/slack',
    icon: <QuestionMarkCircleIcon />,
    text: 'Need help?',
  },
  {
    href: 'https://docs.dynamic.xyz',
    icon: <OpenBookIcon />,
    text: 'Docs',
  },
];

export const DashboardLayout: FC = () => {
  const match = useMatch(ROUTES.onboarding);

  const { activeOrganizationId, userProjects, fetchUserProjects } =
    useDashboardContext();

  const { saveBannerParentRef } = useSaveBannerContext();

  const {
    organizationsDropdown,
    showCreateOrganizationModal,
    setShowCreateOrganizationModal,
  } = useOrganizationsDropdown();

  const {
    projectsDropdown,
    setShowCreateProjectModal,
    showRenameProjectModal,
    setShowRenameProjectModal,
    showCreateProjectModal,
  } = useProjectsDropdown();

  const { environmentsDropdown, openLockedLiveModal, setOpenLockedLiveModal } =
    useEnvironmentsDropdown();

  return (
    <div className={styles.container}>
      {!match && (
        <TopBar
          environmentsDropdown={environmentsDropdown}
          helpButtons={helpButtons}
          organizationsDropdown={organizationsDropdown}
          projectsDropdown={projectsDropdown}
        />
      )}

      <div className={styles.wrapper}>
        <div ref={saveBannerParentRef} className={styles['save-banner']} />

        <div
          className={classNames(styles.content, {
            [styles['content--without-padding']]: match,
          })}
        >
          <div className={styles.sidebar}>
            <Navigation hideNavigation={!!match} />
          </div>

          <div className={styles.outlet}>
            <Outlet />
          </div>
        </div>
      </div>

      <AnimatePresence>
        {openLockedLiveModal && (
          <AddPaymentMethodInfoModal
            handleClose={() => setOpenLockedLiveModal(false)}
          />
        )}
      </AnimatePresence>

      {showCreateOrganizationModal && (
        <AddOrganizationModal
          setShowModal={() => setShowCreateOrganizationModal(false)}
        />
      )}

      {showCreateProjectModal && activeOrganizationId && (
        <AddProjectModal
          setShowModal={() => setShowCreateProjectModal(false)}
          organizationId={activeOrganizationId}
        />
      )}
      {showRenameProjectModal && activeOrganizationId && (
        <UpdateProjectModal
          onClose={() => setShowRenameProjectModal(undefined)}
          onProjectUpdate={() => {
            fetchUserProjects();
          }}
          projectId={showRenameProjectModal}
          projectName={
            userProjects.filter(
              (project) => project.id === showRenameProjectModal,
            )[0].name
          }
          projects={userProjects}
        />
      )}
    </div>
  );
};
