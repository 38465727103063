import { FC } from 'react';

import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { useSettingsContext } from '../../../../context/SettingsContext';
import { RecoveryIcon } from '../../../../../icons';
import Header from '../../SingleChain/Header';
import { Typography } from '../../../../components/Typography';
import { ModalHashLocations, useModalHashLocation } from '../../utils';
import { MFAModal } from '../MFAModal';

export const MFACard: FC = () => {
  const { t } = useTranslation();
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.Mfa,
  });

  const { settings } = useSettingsContext();

  const isLiveEnabled = Boolean(settings.live.security.mfa?.enabled);
  const isSandboxEnabled = Boolean(settings.sandbox.security.mfa?.enabled);

  return (
    <>
      <AnimatePresence>
        {showModal && <MFAModal onClickClose={() => handleShowModal(false)} />}
      </AnimatePresence>
      <button
        type='button'
        onClick={() => handleShowModal(true)}
        className='max-w-[350px] p-4 border border-cloud-2 rounded-xl flex flex-col justify-start items-start'
      >
        <Header
          Icon={RecoveryIcon}
          name={t('integrations.wallets.providers.mfa.title')}
          isLiveActive={isLiveEnabled}
          isSandboxActive={isSandboxEnabled}
        />
        <Typography
          variant='paragraph-2'
          weight='regular'
          className='text-gray-1 mt-4'
        >
          {t('integrations.wallets.providers.mfa.content')}
        </Typography>
      </button>
    </>
  );
};
